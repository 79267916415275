import React, { forwardRef, PropsWithChildren } from "react"
import "./Dialog.scss"

interface Props extends PropsWithChildren {
  footer?: React.ReactElement
}

const Dialog = forwardRef<HTMLDialogElement, Props>((props, ref) => {
  return (
    <dialog ref={ref}>
      {props.children}
      <div>{props.footer}</div>
    </dialog>
  )
})

export default Dialog
