import "./Flag.scss"

export type JobRiskRating = "high" | "moderate" | "low"
type Size = "large" | "default" | "small"

interface Props {
  type?: JobRiskRating
  size?: Size
  text: string
}

const Flag = ({ type, size, text }: Props) => {
  return (
    <div className="flag">
      <span className={`${type} ${size}`}>{text}</span>
    </div>
  )
}
export default Flag
