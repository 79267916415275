import { Slider, TextInput, ToggleInput } from "@fsg/gui-bits"
import { useEffect, useState } from "react";
import { CustomRiskProfileSettings } from "../../../../types/types";

interface Props {
  formData: CustomRiskProfileSettings
  handleSetFormData: (name: string, value: any) => void
  footer: any
}

type FilterComponent = {
  name: string
  label?: string
  component: React.ReactNode
  className?: string
}

const FilterComponentsForm = ({
  formData,
  handleSetFormData,
  footer,
}: Props) => {

  const [complete, setComplete] = useState([0, 100]);

  useEffect(() => {

    //convert percentComplete to array of numbers
    if (typeof formData?.percentComplete === "string") {
      setComplete([0, Number(formData?.percentComplete)]);
    } else {
      setComplete(formData?.percentComplete as unknown as number[]);
    }

  }, [formData?.percentComplete])


  const customFilterComponents: Array<Array<FilterComponent>> = [
    [
      {
        name: "tempLaborRatio",
        label: `Temp Labor Ratio`,
        component: (
          <TextInput
            type="number"
            placeholder="0"
            value={formData?.tempLaborRatio || undefined}
            onChange={(e) => {
              handleSetFormData("tempLaborRatio", e.target.value)
            }}
          />
        ),
      },
      {
        name: "consUnderbillings",
        label: `Consecutive Underbillings`,
        component: (
          <TextInput
            type="number"
            placeholder="0"
            value={formData?.consUnderbillings || undefined}
            onChange={(e) => {
              handleSetFormData("consUnderbillings", e.target.value)
            }}
          />
        ),
      },
    ],
    [
      {
        name: "budgetAdjustments",
        label: `Budget Adjustments`,
        component: (
          <TextInput
            type="number"
            placeholder="0"
            value={formData?.budgetAdjustments || undefined}
            max={100}
            onChange={(e) => {
              handleSetFormData("budgetAdjustments", e.target.value)
            }}
          />
        ),
      },
      {
        name: "percentComplete",
        label: "Percent Complete",
        className: "percent-complete",
        component: (
          <div style={{width:200, display:"inline-block", marginRight:"15px"}}>
            <Slider
              values={complete}
              onChange={(values) => {
                handleSetFormData("percentComplete", values as unknown as string[])
              }}
              minValue={0}
              maxValue={100}
              thumbContent={(props) => <div {...props.props}>{props.value}</div>}
              thumbClassName="slider-thumb"
            />
          </div>
        ),
      },
    ],
    [
      {
        name: "pastDueInvoices",
        label: "Number of Past Due Invoices",
        component: (
          <TextInput
            type="number"
            placeholder="0"
            value={formData?.pastDueInvoices || undefined}
            onChange={(e) => {
              handleSetFormData("pastDueInvoices", e.target.value)
            }}
          />
        ),
      },
      {
        name: "pastDueInvoicesAmount",
        label: "Value of Past Due Invoices",
        component: (
          <TextInput
            type="number"
            placeholder="0"
            value={formData?.pastDueInvoicesAmount || undefined}
            onChange={(e) => {
              handleSetFormData("pastDueInvoicesAmount", e.target.value)
            }}
          />
        ),
      },
    ],
    [
      {
        name: "actualHrsExceedsBaseline",
        className: "toggle",
        component: (
          <label className="toggle-label">
            <div>Actual Hrs Exceeds Baseline</div>
            <ToggleInput
              label=""
              checked={formData?.actualHrsExceedsBaseline}
              onChange={(e) => {
                handleSetFormData("actualHrsExceedsBaseline", e.target.checked)
              }}
            />
          </label>
        ),
      },
      {
        name: "forecastHrsExceedsBaseline",
        className: "toggle",
        component: (
          <label className="toggle-label">
            <div>Forecast Hrs Exceeds Baseline</div>
            <ToggleInput
              label=""
              checked={formData?.forecastHrsExceedsBaseline}
              onChange={(e) => {
                handleSetFormData(
                  "forecastHrsExceedsBaseline",
                  e.target.checked
                )
              }}
            />
          </label>
        ),
      },
      {
        name: "predictedHrsExceedsBaseline",
        className: "toggle",
        component: (
          <label className="toggle-label">
            <div>Predicted Hrs Exceeds Baseline</div>
            <ToggleInput
              label=""
              checked={formData?.predictedHrsExceedsBaseline}
              onChange={(e) => {
                handleSetFormData(
                  "predictedHrsExceedsBaseline",
                  e.target.checked
                )
              }}
            />
          </label>
        ),
      },
      {
        name: "materialActualOver",
        className: "toggle",
        component: (
          <label className="toggle-label">
            <div>Material Actual Exceeds Baseline</div>
            <ToggleInput
              label=""
              checked={formData?.materialActualOver}
              onChange={(e) => {
                handleSetFormData("materialActualOver", e.target.checked)
              }}
            />
          </label>
        ),
      },
      {
        name: "materialBudgetOver",
        className: "toggle",
        component: (
          <label className="toggle-label">
            <div>Material Budget Exceeds Baseline</div>
            <ToggleInput
              label=""
              checked={formData?.materialBudgetOver}
              onChange={(e) => {
                handleSetFormData("materialBudgetOver", e.target.checked)
              }}
            />
          </label>
        ),
      },
    ],
  ]

  return (
    <div>
      {customFilterComponents.map((componentGroup, key1) => {
        return (
          <div className="custom-filters-component-group" key={key1}>
            {componentGroup.map((filterComponent, key2) => (
              <div key={key2}>
                {filterComponent.label ? (
                  <label>{filterComponent.label}</label>
                ) : null}
                <span
                  className={
                    filterComponent.className ?  "" : "custom-filter-text-input"
                  }
                >
                  {filterComponent.component}
                </span>
              </div>
            ))}
          </div>
        )
      })}
      {footer}
    </div>
  )
}

export default FilterComponentsForm
