import { SelectInput } from "@fsg/gui-bits"
import { JobFilters } from "../../../../helpers/job-filters"
import useDivisions from "../../../../hooks/useDivisions"
import classnames from "classnames"
import { excludedDivisions } from "helpers/excludedDivisions"

interface Props {
  filtersState: JobFilters | null
  setFilters: (filters: Partial<JobFilters>) => void
}

const DivisionSelect = ({ filtersState, setFilters }: Props) => {
  const { data: divisions, status: divisionsStatus } = useDivisions()

  const divisionOptions = divisions
    .filter((division) => {
      // exclude MDC and corporate divisions from options
      return !excludedDivisions.includes(division.number)
    })
    .map((division) => {
      return {
        label: `${division.number} - ${division.name}`,
        value: division.number,
      }
    })

  return (
    <SelectInput
      className={classnames("select-input", "division-input", filtersState?.divisions[0] !== "All" && "applied-filter")}
      loading={divisionsStatus === "pending"}
      placeholder={`Division: ${filtersState?.divisions.join("") || "All"}`}
      options={[{ label: "All", value: "All" }].concat(divisionOptions)}
      value={filtersState?.divisions[0]}
      onChange={(e) => {
        if (e.target.value === "") {
          setFilters({ divisions: [] })
          return
        }

        setFilters({ divisions: [e.target.value] })
      }}
    />
  )
}
export default DivisionSelect
