import { Button, SelectInput, ToggleInput } from "@fsg/gui-bits";
import { useRef, useState } from "react"
import { ReactComponent as FilterIcon } from "../../../assets/filter-icon.svg"
import { getBaselineJobFilters, JobFilters } from "../../../helpers/job-filters"
import useMediaQuery from "../../../hooks/useMediaQuery/useMediaQuery"
import useAppStore from "../../../store/store"
import CustomProfilesModal from "./CustomProfilesModal"
import DivisionSelect from "./Inputs/DivisionSelect"
import JobValueRange from "./Inputs/JobValueRange"
import { ReactComponent as CustomIcon } from "../../../assets/custom.svg"
import { CustomRiskProfile, JobInterface } from "../../../types/types"
import classnames from "classnames"
import "./Filters.scss"

interface Props {
  initialFilters: JobFilters
}

const Filters = ({ initialFilters }: Props) => {
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery("(min-width: 1200px)")
  const modalAnchorRef = useRef<HTMLButtonElement>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const { filterMode, setFilterMode } = useAppStore((state) => state.dashboard)
  const {
    jobs,
    filters: filtersState,
    setFilters,
  } = useAppStore((state) => state.jobs)
  const { filterId: customFilterId } = useAppStore((state) => state.dashboard)
  const {
    data: { risk_profiles: customRiskProfilesData },
  } = useAppStore((state) => state.userSettings)

  const filterExceptions = (job: JobInterface) => {
    const excludeJobTypes = ["99"]
    const jobTypeException = !excludeJobTypes.includes(job["job-type"])
    const shouldExclude = jobTypeException

    return shouldExclude
  }

  const jobTypes = Array.from(
    new Set(
      jobs?.filter(filterExceptions).map((x) => x["job-type"].toUpperCase()) ||
        []
    )
  ).sort()

  const handleOpenClose = () => {
    setOpen((prev) => !prev)
  }

  const toggleButton = (
    <Button
      buttonType="secondary"
      className="filters-btn"
      withIcon
      onClick={handleOpenClose}
    >
      <FilterIcon />
    </Button>
  )

  const applyHighRiskFilterOption = () => {
    setFilters({
      ...getBaselineJobFilters(jobs!, filtersState.divisions[0]),
      // only high risk option requires this prop, as only other filter option currently is "custom"
      risk: ["high"],
    })
  }

  const applyCustomFilterOption = (
    filterId: number,
    profiles: CustomRiskProfile[]
  ) => {
    const activeProfile = profiles[filterId]

    setFilters({
      ...activeProfile.settings,
    })
  }

  const onToggleHighRisk = (e: React.ChangeEvent<HTMLInputElement>) => {
    const highRiskSelected = e.target.checked

    if (highRiskSelected) {
      setFilterMode("high")
      applyHighRiskFilterOption()
    } else if (customRiskProfilesData && customFilterId !== null) {
      setFilterMode("custom")
      applyCustomFilterOption(
        customFilterId,
        customRiskProfilesData
      )
    } else {
      setFilterMode("default")
    }
  }


  const filters = (
    <>
      <div className="filter-group">
        <p>
          <strong>Risk Profile</strong>
        </p>
        <div className="divider"></div>
        <div className="risk-profile-options">
          <div className="risk-profile-option">
            <label>
              <strong>High Risk</strong>
              <ToggleInput
                name="risk-profile"
                checked={filterMode === "high"}
                value={"high"}
                label=""
                reversed
                onChange={onToggleHighRisk}
              />
            </label>
          </div>
        </div>
        <Button
          buttonType={filterMode === "custom" ? "primary" : "secondary"}
          ref={modalAnchorRef}
          className="custom-risk-button"
        >
          <CustomIcon
            style={{ width: 18, height: 18 }}
            className={filterMode === "custom" ? `active` : ``}
          />
          Custom
        </Button>
        <CustomProfilesModal
          setFilters={setFilters}
          //initialFilters={initialFilters}
          anchor={modalAnchorRef}
          shown={modalOpen}
          setShown={setModalOpen}
        />
      </div>
      <div className="filter-group">
        <JobValueRange
          filtersState={filtersState}
          setFilters={setFilters}
        />
        <SelectInput
          className={classnames(
            "select-input",
            filtersState?.jobTypes?.[0] ? "applied-filter" : ""
          )}
          placeholder={`Type: All`}
          options={[{ label: "All", value: "" }].concat(
            jobTypes?.map((x) => ({ label: x, value: x }))
          )}
          value={filtersState?.jobTypes?.[0]}
          onChange={(e) => {
            if (e.target.value === "") {
              setFilters({ jobTypes: [] })
              return
            }

            setFilters({ jobTypes: [e.target.value] })
          }}
        />
        <DivisionSelect filtersState={filtersState} setFilters={setFilters} />
        {/* <ManagerSelect filtersState={filtersState} setFilters={setFilters} /> */}
      </div>
      {!isDesktop ? toggleButton : null}
    </>
  )

  return (
    <div className="filters-wrapper">
      {open || isDesktop ? filters : toggleButton}
    </div>
  )
}

export default Filters
