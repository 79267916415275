import create from "zustand"
import { JobsSlice, createJobsSlice } from "./jobs"
import { DashboardSlice, createDashboardSlice } from "./dashboard"
import { GlobalSlice, createGlobalSlice } from "./global"
import { UserSettingsSlice, createUserSettingsSlice } from "./userSettings"

/*
Split store into slices
Docs -> https://github.com/pmndrs/zustand/blob/main/docs/guides/slices-pattern.md

Slices will be of shape: {
  [sliceName]: {
    ...state values go here
  }
}

Slice objects are given a parent property with `sliceName` so that components can access specific store slices using dot notation like so:

const { stateValue } = useAppStore((state) => state.sliceName)

TypeScript details for creating slices -> https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#slices-pattern

Slice generic requires special syntax => StateCreator<StateInterface, [], [], StateInterface>
*/

const useAppStore = create<
  JobsSlice & DashboardSlice & GlobalSlice & UserSettingsSlice
>()((...state) => ({
  ...createJobsSlice(...state),
  ...createDashboardSlice(...state),
  ...createGlobalSlice(...state),
  ...createUserSettingsSlice(...state),
}))

export default useAppStore
