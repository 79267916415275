import { LinkButton, Card, LoadingSpinner, SelectInput } from "@fsg/gui-bits"
import {
  BudgetInterface,
  JobInterface,
  LaborBaselineInterface,
  MaterialBaselineInterface,
  ProfitInterface,
  Status,
} from "../../types/types"
import "./JobOverview.scss"
import useGetJobSummary from "./useGetJobSummary"
import Summary from "./Summary/Summary"
import Map from "../Map"
import Header from "./Header/Header"
import RiskKPICards from "./RiskKPICards"
import useJobDataRequest from "../../hooks/useJobDataRequest"
import { endpoints } from "../../constants/endpoints"
import { useState } from "react"
import { JobAnalysisChart } from "./Charts/JobAnalysisChart"
import MaterialBaselineChart from "./Charts/MaterialBaselineChart"
import { JobAnalysis920Chart } from "./Charts/JobAnalysis920Chart"
import BaselineTable from "./BaselineTable"
import PrimaryKPICards from "./PrimaryKPICards"
import useRiskScorecard from "hooks/useRiskScorecard/useRiskScoredcard"
import JobProfitChart from "./Charts/JobProfitChart"

interface Props {
  job: JobInterface
}

const JobOverview = ({ job }: Props) => {
  // JOB SUMMARY DATA
  const {
    data: jobSummary,
    status: jobSummaryStatus,
    error: jobSummaryError,
  } = useGetJobSummary(job.jobno)

  // MATERIAL BASELINES DATA
  const {
    data: materialBaselines,
    status: materialStatus,
    error: materialError,
  } = useJobDataRequest<MaterialBaselineInterface>({
    endpoint: endpoints.material_baselines,
    jobno: job.jobno,
  })

  // LABOR BASELINES DATA
  const {
    data: laborBaselines,
    status: laborStatus,
    error: laborError,
  } = useJobDataRequest<LaborBaselineInterface>({
    endpoint: endpoints.labor_baselines,
    jobno: job.jobno,
  })

  // BUDGETS (RISK RESERVE) DATA
  const {
    data: riskReserve,
    status: riskReserveStatus,
    error: riskReserveError,
  } = useJobDataRequest<BudgetInterface>({
    endpoint: endpoints.budgets,
    jobno: job.jobno,
  })

  // PROFIT PERFORMANCE DATA
  const {
    data: profitPerformance,
    status: profitPerformanceStatus,
    error: profitPerformanceError,
  } = useJobDataRequest<ProfitInterface>({
    endpoint: endpoints.profit_performance,
    jobno: job.jobno,
  })

  // RISK RUBRIC DATA
  const {
    data: scorecard,
    status: scorecardStatus,
    error: scorecardError,
    getMetrics,
  } = useRiskScorecard(job)

  const [laboreBaselinesKey, setLaborBaselinesKey] = useState("hrs")

  if (jobSummaryStatus === "idle") return <></>
  if (jobSummaryStatus === "pending") return <LoadingSpinner />
  if (jobSummaryError) return <p>Error getting job overview</p>
  if (
    (jobSummaryStatus === "fulfilled" && !jobSummary?.summary1.length) ||
    !jobSummary?.summary2.length
  )
    return <p>Error getting job overview</p>
  const { summary1, summary2 } = jobSummary

  const summaryHeader = (
    <div className="summary-header">
      <h3>Job Overview</h3>
      <div className="buttons">
        <LinkButton
          LinkButtonType="secondary"
          size="small"
          className="link-button"
          withIcon
          href={`${process.env.REACT_APP_JOB_REPORTS_APP_URL}/job-summary?jobno=${job.jobno}`}
        >
          Job Reports
        </LinkButton>
      </div>
    </div>
  )

  const selectChartKey = (
    <SelectInput
      className="key-select"
      value={laboreBaselinesKey}
      onChange={(e) => setLaborBaselinesKey(e.target.value)}
    >
      <option value="hrs">Hours</option>
      <option value="amt">Dollars</option>
    </SelectInput>
  )

  return (
    <div className="job-overview">
      <Header job={job} />
      <Card className="summary-wrapper">
        <div className="summary-info">
          {summaryHeader}
          <Summary summaryData={jobSummary.summary1} job={job} />
        </div>
        <div className="map">
          <Map address={job["job-addr"]} />
        </div>
      </Card>
      {scorecardStatus === "pending" || riskReserveStatus === "pending" ? (
        <LoadingSpinner />
      ) : riskReserveError || riskReserve === undefined || scorecardError ? (
        <Card
          className="chart-card primary-kpi-cards"
          style={{ width: "100%" }}
        >
          <p className="error">Error fetching primary data</p>
        </Card>
      ) : (
        <PrimaryKPICards
          job={job}
          summary1={summary1}
          summary2={summary2}
          riskReserveData={riskReserve.budgets}
          scorecard={{ scorecard, metrics: getMetrics(scorecard) }}
        />
      )}
      {riskReserveStatus === "pending" || scorecardStatus === "pending" ? (
        <LoadingSpinner />
      ) : riskReserveError || riskReserve === undefined || scorecardError ? (
        <Card className="chart-card" style={{ width: "100%" }}>
          <p className="error">Error fetching risk data</p>
        </Card>
      ) : (
        <RiskKPICards
          job={job}
          scorecard={{ scorecard, metrics: getMetrics(scorecard) }}
        />
      )}
      <section className="risk-charts">
        <section className="charts">
          <Card className="chart-card" style={{ width: "100%" }}>
            {profitPerformanceStatus === "pending" ? (
              <LoadingSpinner />
            ) : profitPerformanceError ? (
              <p className="error">Error fetching profit performance data</p>
            ) : (
              <JobProfitChart
                data={profitPerformance.data}
                status={profitPerformanceStatus}
                error={profitPerformanceError}
              />
            )}
          </Card>
          <Card className="chart-card labor-chart" style={{ width: "100%" }}>
            {selectChartKey}
            {laborStatus === "pending" ? (
              <LoadingSpinner />
            ) : laborError ? (
              <p className="error">Error fetching labor baselines data</p>
            ) : (
              <>
                {laboreBaselinesKey === "hrs" && (
                  <JobAnalysisChart
                    data={laborBaselines.data}
                    complete={summary1[6].col2}
                    label="Direct Labor Hours"
                    field="hrs"
                  />
                )}
                {laboreBaselinesKey === "amt" && (
                  <JobAnalysisChart
                    data={laborBaselines.data}
                    complete={summary1[6].col2}
                    label="Direct Labor Dollars"
                    field="amt"
                  />
                )}
              </>
            )}
          </Card>
          <Card className="chart-card" style={{ width: "100%" }}>
            <MaterialBaselineChart
              data={materialBaselines?.data}
              status={materialStatus}
              error={materialError}
              complete={summary1[6].col2}
            />
          </Card>
          <Card className="chart-card" style={{ width: "100%" }}>
            {riskReserveStatus === "pending" ? (
              <LoadingSpinner />
            ) : riskReserveError || riskReserve === undefined ? (
              <div className="error">Error fetching 920 Data</div>
            ) : (
              <JobAnalysis920Chart data={riskReserve.budgets} />
            )}
          </Card>
        </section>
      </section>
      {laborStatus === "pending" ? (
        <LoadingSpinner />
      ) : laborError ? (
        <Card className="chart-card" style={{ width: "100%" }}>
          <p className="error">Error fetching labor baselines data</p>
        </Card>
      ) : (
        <BaselineTable
          data={laborBaselines.data}
          loading={laborStatus === ("pending" as Status)}
        />
      )}
    </div>
  )
}

export default JobOverview
