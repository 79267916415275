import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Interaction,
} from "chart.js"
import CrosshairPlugin, { Interpolate } from "chartjs-plugin-crosshair"
import AnnotationPlugin from "chartjs-plugin-annotation"
import { Line } from "react-chartjs-2"
import {ErrorBoundary} from "react-error-boundary";
import FallBack from "../../ErrorFallBack";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  CrosshairPlugin,
  AnnotationPlugin
)
Interaction.modes.interpolate = Interpolate

const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
})

export const JobAnalysisChart = (props) => {
  const { data, label, complete, field } = props

  let activeJobChartData = {
    contract: {
      hrs: [],
      amt: [],
    },
    total: {
      hrs: [],
      amt: [],
    },
    forecast: {
      hrs: [],
      amt: [],
    },
    actual: {
      hrs: [],
      amt: [],
    },
    predicted: {
      hrs: [],
      amt: [],
    },
  }

  data.sort((a, b) => {
    return +new Date(a.fiscal_date) - +new Date(b.fiscal_date)
  })

  data.forEach((d) => {
    let date = +new Date(d.fiscal_date)
    for (let key of ["hrs", "amt"]) {
      activeJobChartData.contract[key].push({
        date: date,
        value: d[`baseline_contract_${key}`],
      })

      activeJobChartData.total[key].push({
        date: date,
        value: d[`baseline_total_${key}`],
      })

      activeJobChartData.forecast[key].push({
        date: date,
        value: d[`baseline_forecast_${key}`],
      })

      activeJobChartData.actual[key].push({
        date: date,
        value: d[`baseline_actual_${key}`],
      })
      activeJobChartData.predicted[key].push({
        date: date,
        value: d[`baseline_predicted_${key}`],
      })
    }
  })

  let completeValue = activeJobChartData.actual[field]
    .map((x) => new Date(x.date).toLocaleDateString())
    .indexOf(new Date(complete).toLocaleDateString())

  for (let key in activeJobChartData)
    activeJobChartData[key][field].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    )

  if (completeValue === -1) {
    for (let i = 0; i < activeJobChartData.actual[field].length; i++) {
      //if complete date falls between 2 dates, set completeValue to the index of the date before complete
      if (
        new Date(activeJobChartData.actual[field][i]?.date) <
          new Date(complete) &&
        new Date(activeJobChartData.actual[field][i + 1]?.date) >
          new Date(complete)
      ) {
        completeValue = i + 0.5
        break
      }
    }
    if (completeValue === -1) {
      completeValue = activeJobChartData.actual[field].length - 1
    }
  }

  const chartData = {
    labels: activeJobChartData.actual[field].map((x) => {
      const d = new Date(x.date)
      return `${d.getMonth() + 1}/${d.getFullYear()}`
    }),
    datasets: [
      {
        type: "line",
        label: "Actual",
        data: activeJobChartData.actual[field].map((x) => x.value),
        backgroundColor: "rgba(34, 199, 153, 0.5)",
        borderColor: "rgba(34, 199, 153, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Contract",
        data: activeJobChartData.contract[field].map((x) => x.value),
        backgroundColor: "rgba(240, 69, 51, 0.5)",
        borderColor: "rgba(240, 69, 51, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Budget Total",
        data: activeJobChartData.total[field].map((x) => x.value),
        backgroundColor: "rgba(0, 98, 255, 0.5)",
        borderColor: "rgba(0, 98, 255, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "PM Forecast",
        data: activeJobChartData.forecast[field].map((x) => x.value),
        backgroundColor: "rgba(255, 151, 74, 0.5)",
        borderColor: "rgba(255, 151, 74, 1)",
        fill: false,
        borderDash: [5],
      },
      {
        type: "line",
        label: "Predicted",
        data: activeJobChartData.predicted[field].map((x) => x.value),
        backgroundColor: "rgba(255, 207, 82, 0.5)",
        borderColor: "rgba(255, 207, 82, 1)",
        fill: false,
        borderDash: [5],
      },
    ],
  }

  return (
      <ErrorBoundary FallbackComponent={FallBack}>
        <div style={{ position: "relative", display: "block", width: "100%" }}>
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 3,
              plugins: {
                title: { text: label, display: true },
                crosshair: { enabled: true },
                tooltip: {
                  mode: "interpolate",
                  intersect: false,
                },
                annotation: {
                  annotations: {
                    line1: {
                      drawTime: "afterDatasetsDraw",
                      adjustScaleRange: true,
                      type: "line",
                      scaleID: "x",
                      value: completeValue,
                      label: {
                        content: `Complete Date ${new Date(
                          complete
                        ).toLocaleDateString()}`,
                        enabled: true,
                        display: true,
                      },
                    },
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: function (value, index, values) {
                      return numberFormatter.format(value)
                    },
                  },
                },
              },
            }}
            plugins={CrosshairPlugin}
          />
        </div>
      </ErrorBoundary>
  )
}
