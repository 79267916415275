import { useEffect, useState } from "react"
import { request } from "../../api"
import { endpoints } from "../../constants/endpoints";
import { Status } from "../../types/types"
import { DivisionInterface } from "./types"

const useDivisions = () => {
  const [data, setData] = useState<DivisionInterface[]>([])
  const [error, setError] = useState<Error | null>(null)
  const [status, setStatus] = useState<Status>("idle")
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      if (loaded) return

      try {
        const data: DivisionInterface[] = await request({
          path: `${endpoints.divisions}`,
        })

        const deduped = data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.number === value.number)
        )
        setData(deduped)
        setLoaded(true)
        setStatus("fulfilled")

      } catch (e) {
        setData([])
        setError(e as Error)
        setStatus("rejected")
        console.error(e)
      }
    }

    fetchData()
  }, [data.length, loaded])

  return { data, status, error }
}

export default useDivisions
