import { StateCreator } from "zustand"

export interface GlobalSlice {
  global: {
    isMobile: boolean
    setIsMobile: (arg?: boolean) => void
    isSidebarOpen: boolean
    setIsSidebarOpen: (arg?: boolean) => void
  }
}

export const createGlobalSlice: StateCreator<
  GlobalSlice,
  [],
  [],
  GlobalSlice
> = (set) => ({
  global: {
    isMobile: false,
    setIsMobile: (arg?: boolean) => {
      set((state) => ({
        global: {
          ...state.global,
          isMobile: arg === undefined ? !state.global.isMobile : arg,
        },
      }))
    },

    isSidebarOpen: true,
    setIsSidebarOpen: (arg?: boolean) =>
      set((state) => ({
        global: {
          ...state.global,
          isSidebarOpen: arg === undefined ? !state.global.isSidebarOpen : arg,
        },
      })),
  },
})
