import {
  FinancialText,
  KPICard,
  KPICardButton,
  NumberText,
  SubHeaderText,
} from "@fsg/gui-bits"
import {
  BudgetInterface,
  JobInterface,
  JobRiskScorecardInterface,
  ScorecardMetric,
  Summary1Interface,
  Summary2Interface,
} from "types/types"
import { ReactComponent as Money } from "assets/money.svg"
// import { ReactComponent as FileUrgent } from "assets/file-urgent.svg"
import { ReactComponent as ClipboardComplete } from "assets/clipboard-complete.svg"
import { ReactComponent as Calendar } from "assets/calendar.svg"
import { ReactComponent as Percent } from "assets/percent.svg"
import formatCurrency from "utils/formatCurrency"
import useJobMeta from "../../../hooks/useJobMeta"

const formatDollar = formatCurrency.standard

interface CardProps {
  isButtonCard?: boolean
  KPIProps: {
    className?: string
    label: string | JSX.Element
    primaryColor?: "red" | "green" | "black"
    primary: React.ReactNode | string
    secondary?: string
    secondaryColor?: "red" | "green" | "black"
    icon?: React.FunctionComponent
    href?: string
    secondaryClassname?: string
  }
}

interface Scorecard {
  scorecard: JobRiskScorecardInterface
  metrics: Map<string, ScorecardMetric>
}

interface Props {
  job: JobInterface
  summary1: Summary1Interface[]
  summary2: Summary2Interface[]
  riskReserveData: BudgetInterface[]
  scorecard: Scorecard | undefined
}

const PrimaryKPICards = ({
  job,
  summary1,
  summary2,
  riskReserveData,
  scorecard,
}: Props) => {
  const getRiskPoints = (key: string) => {
    // if key doesnt exist in the scorecard metrics, we still need some string to show in the kpi card
    if (scorecard?.metrics.get(key) === undefined) return "-"
    return `${scorecard?.metrics.get(key)?.score} / ${scorecard?.metrics.get(key)?.max
      }`
  }

  const meta = useJobMeta(job.jobno);

  console.log(meta.data);

  const balance920 = riskReserveData
    .filter((x) => x.cost_code === "920")
    .reduce((a, b) => a + b.other, 0)

  const primaryKpiCards: CardProps[] = [
    {
      KPIProps: {
        className: `kpi-card primary-card`,
        label: `Contract Value`,
        primary: <FinancialText>{job.atrisk.toString()}</FinancialText>,
        icon: ClipboardComplete,
        secondaryClassname: `risk-points`,
      },
    },
    {
      KPIProps: {
        className: `kpi-card primary-card`,
        label: `Schedule`,
        primary: `${meta?.data?.sch_start || "?"} - ${meta?.data?.sch_comp || "?"}`,
        icon: Calendar,
        secondaryClassname: `risk-points`,
      },
    },
    {
      KPIProps: {
        className: `kpi-card primary-card`,
        label: `Percent Complete`,
        primary: `${job["pct-complete"]} %`,
        secondaryClassname: `risk-points`,
      },
    },
  ]

  const generalRiskFactors: CardProps[] = [
    {
      KPIProps: {
        className: `kpi-card`,
        label: `920 Balance`,
        primary: <FinancialText>{`${balance920}`}</FinancialText>,
        secondary: `Risk Points: ${getRiskPoints(
          "risk_reserve_pct_remaining_budget"
        )}`,
        icon: Money,
        secondaryClassname: `risk-points`,
      },
    },
    {
      KPIProps: {
        className: `kpi-card`,
        label: `920 as % of Total Remaining Cost`,
        primaryColor: job.pct920remain <= 2 ? `red` : undefined,
        primary: (
          <NumberText
            percent
            className="number-text"
          >{`${job.pct920remain.toFixed(2)}`}</NumberText>
        ),
        icon: Money,
        secondaryClassname: `risk-points`,
      },
    },
    {
      KPIProps: {
        className: `kpi-card`,
        label: `Consecutive Under Billing`,
        primaryColor: job[`cons-undrbil`] > 2 ? `red` : undefined,
        primary: (
          <NumberText
            decimals={0}
            className="number-text"
          >{`${job["cons-undrbil"]}`}</NumberText>
        ),
        secondary: `Risk Points: ${getRiskPoints("consecutive_underbillings")}`,
        icon: ClipboardComplete,
        secondaryClassname: `risk-points`,
      },
    },
    {
      isButtonCard: true,
      KPIProps: {
        className: `kpi-card kpi-button-card`,
        label: (
          <a
            href={`${process.env.REACT_APP_JOB_REPORTS_APP_URL}/invoices?jobno=${job.jobno}`}
          >
            Past Due Invoices Count
          </a>
        ),
        primaryColor: job[`ar-over90-ct`] > 0 ? `red` : undefined,
        primary: `${job[`ar-over90-ct`]}`,
        icon: ClipboardComplete,
        secondary: `Risk Points: ${getRiskPoints("past_due_invoice_count")}`,
        secondaryClassname: `risk-points`,
      },
    },
    {
      isButtonCard: true,
      KPIProps: {
        className: `kpi-card kpi-button-card`,
        label: (
          <a
            href={`${process.env.REACT_APP_JOB_REPORTS_APP_URL}/invoices?jobno=${job.jobno}`}
          >
            Past Due Invoices Value
          </a>
        ),
        primaryColor: job[`ar-over90-ct`] > 0 ? `red` : undefined,
        primary: (
          <span>
            <FinancialText
              className={`financial-text ${job[`ar-over90-amt`] !== 0 ? `error` : ``
                }`}
            >
              {`${job[`ar-over90-amt`].toString()}`}
            </FinancialText>
          </span>
        ),
        icon:Money,
        secondary: `Risk Points: ${getRiskPoints("past_due_invoice_total")}`,
        secondaryClassname: `risk-points`,
      },
    },
    {
      KPIProps: {
        className: `kpi-card`,
        label: `WC as % of Contract`,
        primaryColor: (job.wc_tot / job.atrisk) >= .25 ? "red" : undefined,
        primary: (
          <div className="kpi-card-primary-wrapper">
            <span>
              {((job.wc_tot / job.atrisk) * 100).toFixed(0)}%</span>{" "}
            <span>
              <span className="reference-value">
                {formatDollar(job.wc_tot)}
              </span>
            </span>
          </div>
        ),
        secondary: `Risk Points: ${getRiskPoints("wc_pct_of_contract")}`,
        icon: Percent,
        secondaryColor: `black`,
        secondaryClassname: `risk-points`,
      },
    },
    {
      KPIProps: {
        className: `kpi-card`,
        label: "PM Concurrent Job Count",
        icon: ClipboardComplete,
        primaryColor: job.pm_job_ct >= 10 ? "red" : undefined,
        primary: (
          <div className="kpi-card-primary-wrapper">
            <span>
              {job.pm_job_ct}</span>{" "}
            <span>
              <span className="current-value">
                {formatDollar(job.pm_job_value)}
              </span>
            </span>
          </div>
        ),
        secondary: `Risk Points: ${getRiskPoints("pm_concurrent_job_count")}`,
        secondaryClassname: `risk-points`,
      }
    }
  ]

  const renderCards = (props: CardProps, key: number) => {
    if (props.isButtonCard) {
      return <KPICardButton key={key} {...props.KPIProps} />
    }
    return <KPICard key={key} {...props.KPIProps} />
  }

  return (
    <>
      <section className="primary-kpi-cards row">
        {primaryKpiCards.map(renderCards)}
      </section>
      <SubHeaderText>General Risk Factors</SubHeaderText>
      <section className="primary-kpi-cards row">
        {generalRiskFactors.map(renderCards)}
      </section>
    </>
  )
}
export default PrimaryKPICards
