import "./DashboardPage.scss";
import { Outlet, useNavigate } from "react-router-dom";
import Filters from "./Filters/Filters";
import { ContextType } from "./useJobsContext";
import { JobInterface } from "../../types/types";
import { LoadingSpinner } from "@fsg/gui-bits";
import useAppStore from "../../store/store";
import {
  getFilteredJobs,
  shouldFilterJobs,
} from "../../helpers/job-filters";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@fsg/apps-api-client-react";
import { excludedDivisions } from "helpers/excludedDivisions";
import ReactGA from "react-ga4";

export type GoToJob = (job: JobInterface) => void;

function DashboardPage() {
  const navigate = useNavigate();
  const { jobs, error, status, filters, setFilters } = useAppStore((state) => state.jobs);
  const { selectedJobNumbers, setSelectedJobNumbers } = useAppStore(
    (state) => state.dashboard
  )
  const { user } = useContext(AuthContext);

  const [filteredJobs, setFilteredJobs] = useState(jobs || []);

  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'Dashboard Page'});

  useEffect(() => {
    if (filters.divisions.length === 0 && !excludedDivisions.includes(`${user?.division}`))
      setFilters({ ...filters, divisions: [`${user?.division}`] });

    if (shouldFilterJobs(filters) && jobs) {
      setFilteredJobs(getFilteredJobs(jobs, filters));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, user, jobs]);


  const goToJob: GoToJob = (job: JobInterface) => {
    navigate(`/dashboard/job-specific-analysis/${job.jobno}`);
  };

  const handleSelectJob = (jobNo: string) => {

    document.title = `Dashboard | ${jobNo} | FSG`;
    if (selectedJobNumbers.includes(jobNo)) {
      setSelectedJobNumbers(
        selectedJobNumbers.filter((current) => current !== jobNo)
      );
      return
    }
    setSelectedJobNumbers([jobNo]);
  };

  const handleClearSelection = () => {
    setSelectedJobNumbers([]);
  };

  //const baselineFilters = getBaselineJobFilters(jobs || [], `${user?.division}`);

  const outletContext: ContextType = {
    filters,
    //baselineFilters : filters,
    jobs,
    filteredJobs,
    status,
    error,
    goToJob,
    selectedJobNumbers,
    handleSelectJob,
    handleClearSelection,
  };

  return (
    <div>
      <Filters initialFilters={filters} />
      {status === "pending" ? (
        <LoadingSpinner />
      ) : (
        <div className="features">
          <Outlet context={outletContext} />
        </div>
      )}
    </div>
  );
}

export default DashboardPage;
