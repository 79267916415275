import { SerializedError } from "@reduxjs/toolkit"
import { useOutletContext } from "react-router-dom"
import { JobInterface, Status } from "../../types/types"
import { GoToJob } from "./DashboardPage"
import { JobFilters } from "../../helpers/job-filters"

export type ContextType = {
  filters: JobFilters | null
  jobs: JobInterface[] | undefined
  filteredJobs: JobInterface[]
  status: Status
  error: SerializedError | null
  goToJob: GoToJob
  selectedJobNumbers: string[] | undefined
  handleSelectJob: (jobNumber: string) => void
  handleClearSelection: () => void
}

export function useJobsContext() {
  return useOutletContext<ContextType>()
}
