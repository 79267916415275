import { useEffect, useState } from "react"
import { request } from "../../api"
import { Status } from "../../types/types"

interface Options {
  endpoint: string
  jobno?: string
}

const useJobDataRequest = <T,>({ endpoint, jobno }: Options) => {
  const [data, setData] = useState<T | any>()
  const [status, setStatus] = useState<Status>("idle")
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!jobno) return
    setStatus("pending")
    const fetchData = async () => {
      try {
        const data: any = await request({
          path: `${endpoint}?jobno=${jobno}`,
        })
        setData(data)
        setStatus("fulfilled")
        setError(null)
      } catch (e) {
        setData(undefined)
        setError(e as Error)
        setStatus("rejected")
      }
    }

    fetchData()
  }, [endpoint, jobno])

  return { data, status, error }
}

export default useJobDataRequest
