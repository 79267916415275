import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "@fsg/apps-api-client-react";
import "./index.css";
import ReactGA from "react-ga4";

ReactGA.initialize([{
  trackingId: "G-7X57F8ST6X",
  gaOptions: {}
}]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  //<React.StrictMode>
  <AuthProvider autoLogin={false} baseUrl={process.env.REACT_APP_API_GATEWAY_BASE_URL}>
    <Router />
  </AuthProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
