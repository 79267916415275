import { Button } from "@fsg/gui-bits"
import "./SaveJob.scss"
import { ReactComponent as StarIcon } from "assets/star.svg"
import useToggleJob from "hooks/useToggleJob"

interface Props {
  jobNumber: string
}
const SaveJob = ({ jobNumber }: Props) => {
  const { toggleSavedJob, isSaved } = useToggleJob(jobNumber)

  return (
    <Button
      withIcon
      onClick={toggleSavedJob}
      buttonType="ghost"
      className="save-job-button"
    >
      <StarIcon className={`star-icon ${isSaved ? "saved" : ""}`} />
    </Button>
  )
}
export default SaveJob
