import { CopyIcon, HeaderText } from "@fsg/gui-bits"
import { ReactComponent as ShareButton } from "assets/share-button.svg"
import { JobInterface } from "types/types"
import Flag from "../../Flag"
import SaveJob from "components/SaveJob"
import HideJob from "components/HideJob"

interface Props {
  job: JobInterface
}
const Header = ({ job }: Props) => {

  return (
    <div className="header">
      <CopyIcon
        rounded
        withIcon
        copyText={`${process.env.REACT_APP_MANAGER_APP_URL}/${job.jobno}`}
      >
        <ShareButton />
      </CopyIcon>
      <HeaderText>{job.jobnm}</HeaderText>
      <span className="job-number">{job.jobno}</span>
      <Flag type={job.risk} size="large" text={`${job.risk} Risk`} />
      <SaveJob jobNumber={job.jobno} />
      <HideJob jobNumber={job.jobno} />
    </div>
  )
}
export default Header
