import { Button, LoadingSpinner } from "@fsg/gui-bits"
import { useEffect, useRef, useState } from "react"
import Dialog from "../../../../components/Dialog"
import {
  CustomRiskProfile,
  CustomRiskProfileSettings,
} from "../../../../types/types"

import FilterComponentsForm from "./FilterComponentsForm"
import useAppStore from "store/store"

interface Props {
  profiles: CustomRiskProfile[]
  setShown: (value: boolean) => void
  closeButtons: React.MutableRefObject<HTMLButtonElement[]>
  baseFilterSettings: CustomRiskProfileSettings
  handleCreateAndApply: (
    profiles: CustomRiskProfile[],
    filtersToApply: CustomRiskProfileSettings
  ) => void
  handleUpdateAndApply: (
    profiles: CustomRiskProfile[],
    filtersToApply: CustomRiskProfileSettings
  ) => void
  clearProfilesAndApply: (profileId: string) => void
  updating: boolean
  updateError: Error | null
}

const TabPane = ({
  profiles,
  handleCreateAndApply,
  handleUpdateAndApply,
  setShown,
  closeButtons,
  baseFilterSettings,
  updating,
  updateError,
}: Props) => {
  const {
    filterId: storedFilterId,
    setFilterMode,
    setFilterId,
  } = useAppStore((state) => state.dashboard)
  const {
    data: { risk_profiles },
  } = useAppStore((state) => state.userSettings)
  const [activeTab, setActiveTab] = useState(storedFilterId || 0)
  const [formData, setFormData] = useState(profiles[activeTab].settings)
  const dialogRef = useRef<HTMLDialogElement>(null)

  const activeProfile = profiles[activeTab]

  const showDialog = () => dialogRef.current?.showModal()
  const closeDialog = () => dialogRef.current?.close()

  useEffect(() => {
    setFormData(activeProfile.settings)
  }, [activeProfile.settings, activeTab])

  const handleChange = (name: string, value: any) => {
    setFormData(
      (prev) => ({ ...prev, [name]: value } as CustomRiskProfileSettings)
    )
  }

  const closeModal = () => {
    setShown(false)
  }

  const clear = () => {
    setFormData(baseFilterSettings)
  }

  const cancel = () => {
    // intended behavior is that the form values will be reset to initial state on closing modal if the user does not save
    setFormData(activeProfile.settings)
    closeModal()
  }

  const create = () => {
    const newProfiles = profiles.map((profile) => {
      return profile._id === activeProfile._id
        ? { ...profile, settings: formData }
        : profile
    })
    handleCreateAndApply(newProfiles, formData)
  }

  const save = () => {
    const newProfiles = profiles.map((profile) => {
      return profile._id === activeProfile._id
        ? { ...profile, settings: formData }
        : profile
    })
    handleUpdateAndApply(newProfiles, formData)
    setFilterMode("custom")
    setFilterId(activeTab)
  }

  const primaryActionButton = risk_profiles.length ? (
    <Button
      style={{ minWidth: "120px", fontWeight: 600 }}
      withIcon
      loading={updating}
      type="button"
      onClick={save}
      // ref={(el) => closeButtons.current.push(el as HTMLButtonElement)}
    >
      {updating ? <LoadingSpinner size="small" /> : `Save & Apply`}
    </Button>
  ) : (
    <Button
      type="button"
      onClick={create}
      // ref={(el) => closeButtons.current.push(el as HTMLButtonElement)}
    >
      Create & Apply
    </Button>
  )

  return (
    <div className="tab-panes">
      <div className="tabs">
        {profiles.map((_, index) => (
          <button
            key={index}
            className={`tab-header-button ${
              activeTab === index ? "active" : ""
            }`}
            onClick={() => {
              setActiveTab(index)
            }}
          >
            Custom Profile {index + 1}
          </button>
        ))}
      </div>
      <div className="tab-pane">
        <FilterComponentsForm
          formData={formData}
          handleSetFormData={handleChange}
          footer={
            <div className="form-footer">
              <Button
                buttonType="secondary"
                onClick={() => {
                  dialogRef.current?.showModal()
                }}
              >
                Clear All
              </Button>
              <div className="buttons-right">
                <Button
                  buttonType="secondary"
                  onClick={() => cancel()}
                  ref={(el) =>
                    closeButtons.current.push(el as HTMLButtonElement)
                  }
                >
                  Cancel
                </Button>
                {primaryActionButton}
              </div>
              <Dialog
                ref={dialogRef}
                footer={
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Button
                      buttonType="primary"
                      onClick={() => {
                        clear()
                        closeDialog()
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      buttonType="secondary"
                      onClick={() => {
                        showDialog()
                      }}
                    >
                      No
                    </Button>
                  </div>
                }
              >
                <p>Are you sure you want to clear inputs?</p>
              </Dialog>
            </div>
          }
        />
      </div>
    </div>
  )
}

export default TabPane
