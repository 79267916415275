import { StateCreator } from "zustand"
import lscache from "lscache"
import { CACHE_TIME } from "constants/cacheTime"

const { DAYS } = CACHE_TIME

export interface DashboardSlice {
  dashboard: {
    selectedJobNumbers: string[]
    setSelectedJobNumbers: (jobNumbers: string[]) => void
    filterMode: string
    setFilterMode: (filterMode: string) => void
    filterModeHistory: {
      prev: string
      current: string
    }
    filterId: number | null
    setFilterId: (filterId: number | null) => void
  }
}

const getInitialFilterMode = () => {
  return lscache.get("fsg-manager/filterMode") || "high"
}

export const createDashboardSlice: StateCreator<
  DashboardSlice,
  [],
  [],
  DashboardSlice
> = (set) => ({
  dashboard: {
    selectedJobNumbers: lscache.get("fsg-manager/selectedJobNumbers") || [],
    setSelectedJobNumbers(jobNumbers: string[]) {
      lscache.set("fsg-manager/selectedJobNumbers", jobNumbers)
      set((state) => ({
        dashboard: { ...state.dashboard, selectedJobNumbers: jobNumbers },
      }))
    },

    filterMode: getInitialFilterMode(),
    setFilterMode: (filterMode: string) => {
      set((state) => {
        const newState = {
          dashboard: {
            ...state.dashboard,
            filterMode,
            filterModeHistory: {
              prev: state.dashboard.filterModeHistory.current,
              current: filterMode,
            },
          },
        }

        lscache.set("fsg-manager/filterMode", filterMode, DAYS[30])
        lscache.set(
          "fsg-manager/filterModeHistory",
          newState.dashboard.filterModeHistory,
          DAYS[30]
        )

        return newState
      })
    },

    filterModeHistory: {
      prev: "",
      current: getInitialFilterMode(),
    },

    filterId: lscache.get("fsg-manager/filterId"),
    setFilterId: (filterId: number | null) => {
      lscache.set("fsg-manager/filterId", filterId, DAYS[30])
      set((state) => ({
        dashboard: { ...state.dashboard, filterId },
      }))
    },
  },
})
