import { JobInterface } from "../types/types"

export interface JobFilters {
  jobValue: [number, number] // atrisk
  tempLaborRatio?: string | null // thrpct
  consUnderbillings?: string | null // cons-undb
  budgetAdjustments?: string | null // pm-adjs-ct
  percentComplete?: string | string[] | null // pct-complete
  actualHrsExceedsBaseline: boolean // actual_labor_hrs > budgeted_labor_hrs
  forecastHrsExceedsBaseline: boolean // mpwr-hrs > budgeted_labor_hrs
  predictedHrsExceedsBaseline: boolean // predicted_labor_hrs > budgeted_labor_hrs
  divisions: string[] // div
  managers: string[] // pmname
  jobTypes: string[] // jobtype
  risk: string[] // risk
  pastDueInvoices?: string | null // ar-over90-ct
  pastDueInvoicesAmount?: string | null // ar-over90-amt
  materialActualOver?: boolean
  materialBudgetOver?: boolean
}

export const getBaselineJobFilters = (jobs: JobInterface[], division: string): JobFilters => {
  const jobValuesArray = jobs.map((job) => job.atrisk).sort((a, b) => a - b)

  const lowerJobValue = jobValuesArray[0]
  const upperJobValue = jobValuesArray[jobValuesArray.length - 1]

  return {
    jobValue: [lowerJobValue, upperJobValue],
    tempLaborRatio: "0",
    consUnderbillings: "0",
    budgetAdjustments: "0",
    percentComplete: ["0", "100"],
    actualHrsExceedsBaseline: false,
    forecastHrsExceedsBaseline: false,
    predictedHrsExceedsBaseline: false,
    divisions: division ? [division] : [],
    managers: [],
    jobTypes: [],
    risk: ["high"],
    pastDueInvoices: "0",
    pastDueInvoicesAmount: "0",
    materialActualOver: false,
    materialBudgetOver: false,
  }
}

export const shouldFilterJobs = (filters: JobFilters) => {
  return (
    filters?.jobValue ||
    filters?.tempLaborRatio ||
    filters?.consUnderbillings ||
    filters?.budgetAdjustments ||
    filters?.pastDueInvoices ||
    filters?.pastDueInvoicesAmount ||
    filters?.actualHrsExceedsBaseline !== undefined ||
    filters?.forecastHrsExceedsBaseline !== undefined ||
    filters?.predictedHrsExceedsBaseline !== undefined ||
    filters?.materialActualOver !== undefined ||
    filters?.materialBudgetOver !== undefined ||
    (filters && filters.divisions.length > 0) ||
    (filters && filters.managers.length > 0)
  )
}

export const getFilteredJobs = (
  data: JobInterface[],
  filtersState: JobFilters
) => {
  const filteredData = data.filter((job: JobInterface) => {
    const appliedFilters = getAppliedFiltersForJob(filtersState, job)

    return appliedFilters.length === 0
  })

  return filteredData
}

export function getAppliedFiltersForJob(
  filtersState: JobFilters,
  job: JobInterface,
  log: boolean = false
) {
  const {
    jobValue,
    tempLaborRatio,
    consUnderbillings,
    budgetAdjustments,
    percentComplete,
    actualHrsExceedsBaseline,
    forecastHrsExceedsBaseline,
    predictedHrsExceedsBaseline,
    divisions,
    jobTypes,
    managers,
    risk,
    pastDueInvoices,
    pastDueInvoicesAmount,
    materialActualOver,
    materialBudgetOver,
  } = filtersState

  const appliedFilters = []

  if (divisions.length > 0 && divisions[0] !== "All" && !divisions.includes(job.div))
    appliedFilters.push("division")

  if (jobTypes?.length > 0 && !jobTypes.includes(job["job-type"].toUpperCase()))
    appliedFilters.push("job-type")

  if (job.atrisk < jobValue[0] || job.atrisk > jobValue[1])
    appliedFilters.push("job-value")

  if (risk.length > 0 && !risk.includes(job.risk)) appliedFilters.push("risk")

  if (
    tempLaborRatio &&
    tempLaborRatio !== "0" &&
    parseFloat(tempLaborRatio) >= job.thrpct
  )
    appliedFilters.push("temp-labor-ratio")

  if (
    consUnderbillings &&
    consUnderbillings !== "0" &&
    parseFloat(consUnderbillings) > job["cons-undrbil"]
  )
    appliedFilters.push("cons-underbillings")

  if (
    budgetAdjustments &&
    budgetAdjustments !== "0" &&
    parseFloat(budgetAdjustments) > job["pm-adjs-ct"]
  )
    appliedFilters.push("budget-adjustments")

  if (
    percentComplete &&
    percentComplete[0] !== "0" &&
    percentComplete[1] !== "100" &&
    (parseFloat(percentComplete[0]) > job["pct-complete"] ||
    parseFloat(percentComplete[1]) < job["pct-complete"])
  )
    appliedFilters.push("percent-complete")

  if (
    pastDueInvoices &&
    pastDueInvoices !== "0" &&
    parseFloat(pastDueInvoices) > job["ar-over90-ct"]
  )
    appliedFilters.push("past-due-invoices")

  if (
    pastDueInvoicesAmount &&
    pastDueInvoicesAmount !== "0" &&
    parseFloat(pastDueInvoicesAmount) > job["ar-over90-amt"]
  )
    appliedFilters.push("past-due-invoice-amount")

  if (
    actualHrsExceedsBaseline &&
    job.actual_labor_hrs <= job["contract_labor_hrs"]
  )
    appliedFilters.push("actual-hrs-exceeds-baseline")

  if (
    forecastHrsExceedsBaseline &&
    job["mpwr-hrs"] <= job["contract_labor_hrs"]
  )
    appliedFilters.push("forecast-hrs-exceeds-baseline")

  if (
    predictedHrsExceedsBaseline &&
    job["predicted_labor_hrs"] <= job["contract_labor_hrs"]
  )
    appliedFilters.push("predicted-hrs-exceeds-baseline")

  if (materialActualOver && job["mat-actual"] <= job["mat-base"]) {
    appliedFilters.push("material-actual-over")
  }

  if (materialBudgetOver && job["mat-budget"] <= job["mat-base"]) {
    appliedFilters.push("material-budget-over")
  }

  if (managers.length > 0 && !managers.includes(job["pmname"]))
    appliedFilters.push("manager")
  return appliedFilters
}
