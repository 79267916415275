import { DateText } from "@fsg/gui-bits"
import {
  CSSProperties,
  DetailedHTMLProps,
  TdHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react"
import { ReactComponent as EyeIcon } from "../../assets/eye.svg"
import { ReactComponent as EyeOffIcon } from "../../assets/eye-off.svg"
import "./DivisionManpowerTable.scss"
import { request } from "api"
import Loading from "components/Loading"
import useAppStore from "store/store"
import { Job, Props, MetaRollups } from "./DivisionManpowerTable.types"
import { transformData, isBeforeToday, getBudgetToComplete, getPmName } from "./DivisionManpowerTable.helpers"

export const currentDate = new Date()

const DivisionManpowerTable = ({ data, fallback }: Props) => {
  const [filters, setFilters] = useState<string[]>([])
  const [jobOverviews, setJobOverviews] = useState(null)
  const [jobs, setJobs] = useState<Job[]>([])
  const [opportunitiesData, setOpportunitiesData] = useState<Job[] | null>([])
  const [dateStrings, setDateStrings] = useState<string[]>([])
  const [dates, setDates] = useState<string[]>([])
  const { jobs : allJobs } = useAppStore((state) => state.jobs);

  useEffect(() => {
    (async () => {
      if (jobs.length === 0 || jobOverviews !== null) return
      
      let data : any = {};
      for (const job of jobs) {
        const jobOverview = await request({
          path: `/v1/jobs/${job.jobNumber}/overview`,
        });
        data[job.jobNumber] = jobOverview;
      }
      setJobOverviews(data);
    })();
  }, [jobs, jobOverviews])

  const toggleHide = useCallback((jobNumber: string) => {
    if (filters.includes(jobNumber)) {
      setFilters((prev) => prev.filter((string) => string !== jobNumber))
      return
    }
    setFilters((prev) => [...prev, jobNumber])
  }, [filters])

  useEffect(() => {

    const jobsData = transformData(data.jobs)
    const opportunitiesData = Object.keys(data.anticipated).length
      ? transformData(data.anticipated)
      : null

    setOpportunitiesData(opportunitiesData)
  
    // console.log({jobsData, opportunitiesData})
    // this has two uses: 1) to set the table column headers and (2) as an index to build the cells for each job's row
    const allCurrentDates = jobsData
      .map((job) => job.fiscalPeriodDates)
      .reduce((arr, accumulator) => accumulator.concat(arr))
  
    // find common dates among all jobs' current fiscal period forecasts
    const dateStrings = Array.from(new Set(allCurrentDates))
    setDateStrings(dateStrings)
  
    const sortedDateStrings = dateStrings.sort((a, b) => {
      const date1 = new Date(a)
      const date2 = new Date(b)
  
      return date1.getTime() - date2.getTime()
    })
  
    const dates = sortedDateStrings

    setDates(dates)
  
    const excludeJobs = (job: Job) => {
      const { forecast } = job
  
      const forecastsOverDates = dates.map((date) => ({
        date,
        ...forecast[date],
      }))
  
      const forecastsAreEmpty = forecastsOverDates.every(
        (data) => data.forecast_men !== 0
      )
  
      const jobIsCompleted = job.meta.sch_com && !isBeforeToday(job.meta.sch_com)
  
      const conditions = [forecastsAreEmpty, jobIsCompleted]
  
      return conditions.every((condition) => condition)
    }
  
    const filteredJobs = jobsData.filter(excludeJobs)
    setJobs(filteredJobs)
  }, [data])

  if (!data.hasOwnProperty("jobs") || Object.keys(data.jobs).length === 0)
    return fallback


  if (!jobs.length) return fallback
  if (jobOverviews === null) return <Loading iconSize="large" />

  const allJobsHidden = filters.length === jobs.length
  const allOpportunitiesHidden = filters.length === opportunitiesData?.length
  const everythingHidden =
    filters.length === jobs.length + (opportunitiesData?.length || 0)

  const mapByField = (array: any[], field: keyof MetaRollups) => {
    return array.map((job) => job.meta[field] || 0)
  }

  const colGroup = (
    <colgroup>
      <col data-col-1 />
      <col data-col-2 />
      <col data-col-3 />
      <col data-col-4 />
      <col data-col-5 />
      <col data-col-6 />
      <col data-col-7 className="lightest-gray" />
      <col data-col-8 className="lightest-gray" />
      <col data-col-9 className="lightest-gray" />
      <col data-col-10 className="lightest-gray" />
      <col data-col-11 className="lightest-gray" />
    </colgroup>
  )

  const tableHeaders = [
    {
      style: { width: `50px` },
      label: ``,
    },
    {
      style: { width: "180px", textAlign: "center" },
      label: `Job Number`,
    },
    {
      style: { width: "250px" },
      label: `Job Name`,
    },
    {
      style: { width: "200px" },
      label: `Project Manager`,
    },
    {
      style: { width: "200px" },
      label: `Start Date`,
    },
    {
      style: { width: "200px" },
      label: `End Date`,
    },
    {
      angledHeader: true,
      style: { width: "100px", overflow: "visible" },
      label: `Current # of Men > 30 hrs`,
    },
    {
      angledHeader: true,
      style: { width: "100px", overflow: "visible" },
      label: `Pay Period Hours`,
    },
    {
      angledHeader: true,
      style: { width: "100px", overflow: "visible" },
      label: `Avg Hrs per Man`,
    },
    {
      angledHeader: true,
      style: { width: "100px", overflow: "visible" },
      label: `Total Hours`
    },
    {
      angledHeader: true,
      style: { width: "120px", overflow: "visible" },
      label: `Budget Remaining`
    },
    ...dates.map((date) => ({
      date: true,
      angledHeader: true,
      style: undefined,
      label: date,
    })),
  ]

  const initialColSpan = tableHeaders.filter((x : any) => !x.angledHeader && !x.date).length;

  const tableHead = (
    <thead>
      <tr>
        {tableHeaders.map(({ angledHeader, style, label }) => {
          const thStyles = (style || { width: "80px" }) as CSSProperties
          if (angledHeader)
            return (
              <th scope="col" style={thStyles}>
                <div>
                  <span>{label}</span>
                </div>
              </th>
            )

          return (
            <th scope="col" style={thStyles}>
              {label}
            </th>
          )
        })}
      </tr>
    </thead>
  )

  const getDataRows = (data: Job[]) => {
    return data.map((job) => {
      const { forecast, meta, jobNumber } = job

      const metaCells: {
        innerHTML: any
        props?: DetailedHTMLProps<
          TdHTMLAttributes<HTMLTableCellElement>,
          HTMLTableCellElement
        >
      }[] = [
        {
          // job number
          innerHTML: jobNumber,
          props: { align: "center" },
        },
        {
          // job name
          innerHTML: meta.name,
        },
        {
          // project manager
          innerHTML: getPmName(jobNumber, allJobs)
        },
        {
          // start date
          innerHTML: meta.sch_sta ? (
            <DateText hideTime>{meta.sch_sta}</DateText>
          ) : (
            ""
          ),
        },
        {
          // end date
          innerHTML: meta.sch_com ? (
            <DateText hideTime>{meta.sch_com}</DateText>
          ) : (
            ""
          ),
        },
        {
          // current # of men > 30 hrs
          innerHTML: meta.last_per_no_men_ovr_30hrs?.toFixed(0) || "-",
          props: {
            align: "center",
            className: filters.includes(jobNumber) ? `offWhite` : ``,
          },
        },
        {
          // pay period hrs
          innerHTML: meta.last_per_hrs?.toFixed(2) || "-",
          props: {
            align: "center",
            className: filters.includes(jobNumber) ? `offWhite` : ``,
          },
        },
        {
          // average hrs per man
          innerHTML:
            meta.last_per_no_men_ovr_30hrs && meta.last_per_hrs
              ? (meta.last_per_hrs / meta.last_per_no_men_ovr_30hrs).toFixed(2)
              : "0.00",
          props: {
            align: "center",
            className: filters.includes(jobNumber) ? `offWhite` : ``,
          },
        },
        {
          // total hours
          innerHTML: (() => {
            return  meta.last_per_no_men_ovr_30hrs && meta.last_per_hrs ? (dates.reduce((acc, date) => { 
              const forecastHours = forecast[date]?.forecast_men || 0
              return acc + forecastHours
            }, 0) * (meta.last_per_hrs / meta.last_per_no_men_ovr_30hrs)).toFixed(0) : "-"
          })(),
          props: {
            align: "center",
            className: filters.includes(jobNumber) ? `offWhite` : ``,
          },
        }, 
        {
          innerHTML: (() => {
            const budget = getBudgetToComplete(jobOverviews?.[jobNumber]);
            
            return budget ? budget.toFixed(0) : "-";
          })(),
          props: {
            align: "center",
            className: filters.includes(jobNumber) ? `offWhite` : ``,
          }
        }
      ]

      const mappedDataByDate = dates.map((date, index) => {
        return (
          <td key={index} align="center">
            {forecast[date] !== undefined ? forecast[date].forecast_men : "-"}
          </td>
        )
      })

      return (
        <tr className={`${filters.includes(jobNumber) ? "filtered" : ""}`}>
          <td>
            <button className="hide-icon" onClick={() => toggleHide(jobNumber)}>
              {filters.includes(jobNumber) ? <EyeOffIcon /> : <EyeIcon />}
            </button>
          </td>
          {metaCells.map(({ innerHTML, props }, index) => (
            <td key={index} {...props}>
              {innerHTML}
            </td>
          ))}
          {mappedDataByDate}
        </tr>
      )
    })
  }

  const sumArray = (condition: boolean) =>
    condition
      ? () => 0
      : (array: number[]) =>
          array.reduce((a, b) => {
            return a + b
          })

  const sumJobsArray = sumArray(allJobsHidden)
  const sumOpportunityArray = sumArray(allOpportunitiesHidden)
  const sumTotalArray = sumArray(everythingHidden)

  const filterHiddenRows = (job: Job) => !filters.includes(job.jobNumber)

  const getTotalsRow = (
    label: string,
    data: Job[],
    sumArrayCallback: (array: number[]) => number
  ) => {
    const totalsColumns: {
      innerHTML: any
      props?: DetailedHTMLProps<
        TdHTMLAttributes<HTMLTableCellElement>,
        HTMLTableCellElement
      >
    }[] = [
      {
        innerHTML: label,
        props: { className: "align-right", colSpan: initialColSpan },
      },

      {
        innerHTML: sumArrayCallback(
          mapByField(data.filter(filterHiddenRows), "last_per_no_men_ovr_30hrs")
        ).toFixed(2),
        props: { align: "center", className: "lighter-gray" },
      },
      {
        innerHTML: (() => {
          const values = data
          .filter(filterHiddenRows)
          .map(({ meta }) =>
            meta.last_per_hrs || 0
          )
          .filter(num => num > 0);

          return (values.length === 0 ? 0 : values.reduce((a, b) => a + b) / values.length).toFixed(2);
        })(),
        
        props: { align: "center", className: "lighter-gray" },
      },
      {
        innerHTML: (() => {
          const values = data
          .filter(filterHiddenRows)
          .map(({ meta }) =>
            meta.last_per_hrs && meta.last_per_no_men_ovr_30hrs
              ? meta.last_per_hrs / meta.last_per_no_men_ovr_30hrs
              : 0
          )
          .filter(num => num > 0);

          return (values.length === 0 ? 0 : values.reduce((a, b) => a + b) / values.length).toFixed(2);

        })(),
        props: { align: "center", className: "lighter-gray" },
      },
      {
        innerHTML: data
          .filter(filterHiddenRows)
          .map(({ meta, forecast }) => {
            return meta.last_per_no_men_ovr_30hrs && meta.last_per_hrs ? dates.reduce((acc, date) => { 
              const forecastHours = forecast[date]?.forecast_men || 0
              return acc + forecastHours
            }, 0) * (meta.last_per_hrs / meta.last_per_no_men_ovr_30hrs) : 0
        }).reduce((a, b) => { return +a + +b }, 0).toFixed(0),
        props: { align: "center", className: "lighter-gray" },
      },
      {
        innerHTML: data
          .filter(filterHiddenRows)
          .map(({ jobNumber }) => getBudgetToComplete(jobOverviews?.[jobNumber]) || 0)
          .reduce((a, b) => { return +a + +b }, 0).toFixed(0),
        props: { align: "center", className: "lighter-gray" },
      }
    ]

    return (
      <tr className="totals-row">
        {totalsColumns.map(({ innerHTML, props }, index) => (
          <td key={index} {...props}>
            {innerHTML}
          </td>
        ))}
        {dates.map((date, index) => {
          return (
            <td key={index} align="center" className="lighter-gray">
              {sumArrayCallback(
                data
                  .filter(({ jobNumber }) => !filters.includes(jobNumber))
                  .map((job) => {
                    return job.forecast[date] !== undefined
                      ? job.forecast[date].forecast_men
                      : 0
                  })
              )}
            </td>
          )
        })}
      </tr>
    )
  }

  const jobDataRows = getDataRows(jobs)
  const jobsTotalsRow = getTotalsRow("", jobs, sumJobsArray)
  const opportunitiesDataRows = opportunitiesData
    ? getDataRows(opportunitiesData)
    : null
  const opportunitiesTotalsRow = opportunitiesData
    ? getTotalsRow(
        "Possible Future Manpower Needs",
        opportunitiesData,
        sumOpportunityArray
      )
    : null
  const bothTotalsRow = opportunitiesData
    ? getTotalsRow(
        "Total Future Manpower Needs",
        jobs.concat(opportunitiesData!),
        sumTotalArray
      )
    : null

  const fullTableColSpan = 10 + dateStrings.length

  return (
    <div className="division-manpower-forecast-table">
      <div className="table-wrapper">
        <table>
          {colGroup}
          {tableHead}
          <tbody>
            {jobDataRows}
            {jobsTotalsRow}
          </tbody>
          {opportunitiesData ? (
            <tbody>
              <tr className="table-body-header-cell">
                <td colSpan={initialColSpan}>Anticipated Projects</td>
                <td colSpan={fullTableColSpan}></td>
              </tr>
              {opportunitiesDataRows}
              {opportunitiesTotalsRow}
              {bothTotalsRow}
            </tbody>
          ) : (
            <tr>
              <td colSpan={fullTableColSpan}>
                <span>
                  <strong>No Anticpated Jobs</strong> | Enter anticipated labor
                  needs in engage include opportunities in forecast.
                </span>
              </td>
            </tr>
          )}
        </table>
      </div>
    </div>
  )
}

export default DivisionManpowerTable
