import { HeaderText } from "@fsg/gui-bits"
import { PropsWithChildren } from "react";
import "./FallbackUI.scss"

interface Props extends PropsWithChildren {
header: string
}
const FallbackUI = ({ header, children }: Props) => {
  return (
    <div id="division-manpower-fallback">
      <div>
        <HeaderText className="header">{header}</HeaderText>
        {children}
      </div>
      
    </div>
  )
}

export default FallbackUI
