import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Interaction,
} from "chart.js";
import CrosshairPlugin, { Interpolate } from "chartjs-plugin-crosshair";
import { Line } from "react-chartjs-2";
import { LoadingSpinner } from "@fsg/gui-bits";
import {ErrorBoundary} from "react-error-boundary";
import FallBack from "../../ErrorFallBack";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  CrosshairPlugin
);

Interaction.modes.interpolate = Interpolate;

const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});

const MaterialBaselineChart = ({ data, status, error, complete }) => {
  if (status === "idle") return <></>;
  if (status === "pending") return <LoadingSpinner />;
  if (status === "rejected" || error) {
    console.error(error);
    return <p>Error getting material data</p>;
  }
  if (!data) return <>No Data</>;

  data.sort((a, b) => new Date(a.fiscal_date) - new Date(b.fiscal_date));


  let completeValue = data.map((x) =>
    new Date(x.fiscal_date).toLocaleDateString()
  ).indexOf(new Date(complete).toLocaleDateString());

  if (completeValue === -1) {
    for (let i = 0; i < data.length; i++) {
      //if complete date falls between 2 dates, set completeValue to the index of the date before complete
      if (new Date(data[i]?.fiscal_date) < new Date(complete) && new Date(data[i + 1]?.fiscal_date) > new Date(complete)) {
        completeValue = i + 0.5;
        break;
      }
    }
    if (completeValue === -1) {
      completeValue = data.length - 1;
    }
  }


  const chartData = {
    labels: data.map((x) =>     {
      const d = new Date(x.fiscal_date);
      return `${d.getMonth() + 1}/${d.getFullYear()}`;
    }),
    datasets: [
      {
        type: "line",
        label: "Actual",
        data: data.map((x) => x["baseline_actual_amt"]),
        backgroundColor: "rgba(34, 199, 153, 0.5)",
        borderColor: "rgba(34, 199, 153, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Contract",
        data: data.map((x) => x["baseline_contract_amt"]),
        backgroundColor: "rgba(240, 69, 51, 0.5)",
        borderColor: "rgba(240, 69, 51, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Budget Total",
        data: data.map((x) => x["baseline_total_amt"]),
        backgroundColor: "rgba(0, 98, 255, 0.5)",
        borderColor: "rgba(0, 98, 255, 1)",
        fill: false,
      },
    ],
  };

  return (
      <ErrorBoundary FallbackComponent={FallBack}>
        <div style={{ position: "relative", display: "block", width: "100%" }}>
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 3,
              plugins: {
                title: { text: "Material Baseline", display: true },
                crosshair: { enabled: true },
                tooltip: {
                  mode: "interpolate",
                  intersect: false,
                },
                annotation: {
                  annotations: {
                    line1: {
                      drawTime: "afterDatasetsDraw",
                      adjustScaleRange: true,
                      type: 'line',
                      scaleID: "x",
                      value: completeValue,
                      label: {
                        content: `Complete Date ${new Date(complete).toLocaleDateString()}`,
                        enabled: true,
                        display: true,
                      }
                    }
                  },
                }
              },
              scales: {
                y: {
                  ticks: {
                    callback: function (value, index, values) {
                      return numberFormatter.format(value);
                    }
                  }
                }
              }
            }}
            plugins={CrosshairPlugin}
          />
        </div>
      </ErrorBoundary>
  );
};

export default MaterialBaselineChart;
