import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Interaction,
} from "chart.js";
import CrosshairPlugin, { Interpolate } from "chartjs-plugin-crosshair";
import { Line } from "react-chartjs-2";
import { LoadingSpinner } from "@fsg/gui-bits";
import {ErrorBoundary} from "react-error-boundary";
import FallBack from "../../ErrorFallBack";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  CrosshairPlugin
);

Interaction.modes.interpolate = Interpolate;

const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});

const JobProfitChart = ({ data, status, error }) => {
  if (status === "idle") return <></>;
  if (status === "pending") return <LoadingSpinner />;
  if (status === "rejected" || error) {
    console.error(error);
    return <p>Error getting profit data</p>;
  }
  if (!data) return <>No Data</>;

  data.sort((a, b) => new Date(a.fiscalDate) - new Date(b.fiscalDate));

  const chartData = {
    labels: data.map((x) =>     {
      const d = new Date(x.fiscalDate);
      return `${d.getMonth() + 1}/${d.getFullYear()}`;
    }),
    datasets: [
      {
        type: "line",
        label: "Revenue",
        data: data.map((x) => x["revenueAmt"]),
        backgroundColor: "rgba(255, 151, 74, 0.5)",
        borderColor: "rgba(255, 151, 74, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Contract Cost",
        data: data.map((x) => x["contractAmt"]),
        backgroundColor: "rgba(240, 69, 51, 0.5)",
        borderColor: "rgba(240, 69, 51, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Budgeted Cost",
        data: data.map((x) => x["budgetAmt"]),
        backgroundColor: "rgba(0, 98, 255, 0.5)",
        borderColor: "rgba(0, 98, 255, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Actual Cost",
        data: data.map((x) => x["costAmt"]),
        backgroundColor: "rgba(34, 199, 153, 0.5)",
        borderColor: "rgba(34, 199, 153, 1)",
        fill: false,
      },
    ],
  };

  return (
      <ErrorBoundary FallbackComponent={FallBack}>
        <div style={{ position: "relative", display: "block", width: "100%" }}>
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 3,
              plugins: {
                title: { text: "Profit Performance", display: true },
                crosshair: { enabled: true },
                tooltip: {
                  mode: "interpolate",
                  intersect: false,
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: function (value, index, values) {
                      return numberFormatter.format(value);
                    }
                  }
                }
              }
            }}
            plugins={CrosshairPlugin}
          />
        </div>
      </ErrorBoundary>
  );
};

export default JobProfitChart;
