import { JobInterface } from "../../../types/types"

export const findAverage = (values: Array<number>) =>
  values.reduce((acc: number, value: number) => acc + value) / values.length

export const getValuesArrayByKey = (
  key: keyof JobInterface,
  array: Array<JobInterface>
) => {
  return array.map((job: JobInterface) => job[key])
}

export const findMedianValueJob = (
  key: keyof JobInterface,
  array: Array<JobInterface>
) => {
  const sorted = [...array].sort(
    (a, b) => (a[key] as number) - (b[key] as number)
  )
  if (sorted.length % 2 === 0) {
    // return value to right of middle because mathematical median will not link to a Job from data
    return sorted[sorted.length / 2]
  }
  const medianIndex = Math.floor(sorted.length / 2)
  return sorted[medianIndex]
}

export const findMaxValueJob = (
  key: keyof JobInterface,
  array: Array<JobInterface>
) => {
  return array.reduce((prev, curr) => {
    let max = (prev[key] as number) > (curr[key] as number) ? prev : curr
    return max
  })
}

export const findMinValueJob = (
  key: keyof JobInterface,
  array: Array<JobInterface>
) => {
  return array.reduce((prev, curr) => {
    let min = (prev[key] as number) < (curr[key] as number) ? prev : curr
    return min
  })
}
