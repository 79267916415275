import { useEffect, useState } from "react"
import { Status } from "../../types/types"

const useGeocoder = (address: string) => {
  const [status, setStatus] = useState<Status>("idle")
  const [position, setPosition] = useState<number[]>()
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setStatus("pending")

    const geocoder = async (address: string) => {
      const url = new URL(
        `https://us-central1-fsg-erp-350414.cloudfunctions.net/orchestration-cf/coordinates`
      )
      url.searchParams.append("address", address)
      const urlString = url.toString()

      try {
        const response = await fetch(urlString)
        const data = await response.json()
        setPosition([data.lat, data.lng])
        setStatus("fulfilled")
      } catch (error) {
        setError(error as Error)
        setStatus("rejected")
        console.error(error)
      }
    }

    geocoder(address)
  }, [address])

  return { position, status, error }
}
export default useGeocoder
