import { DataType, Job } from "./DivisionManpowerTable.types";
import { currentDate } from "./DivisionManpowerTable";

const isCurrentFiscalPeriod = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.getMonth();
  const year = date.getFullYear();

  const isAfterThisMonth = year === currentDate.getFullYear() && month >= currentDate.getMonth();
  const isNextYearBeforeSameMonth = year === currentDate.getFullYear() + 1 && month <= currentDate.getMonth();

  return isAfterThisMonth || isNextYearBeforeSameMonth;
};
export const isBeforeToday = (dateString: string) => {
  const date = new Date(dateString);
  return date.getTime() < currentDate.getTime();
};
export const transformData = (data: DataType) => {
  return Object.entries(data).map(([jobNumber, jobData]): Job => {
    const fiscalPeriodDates = Object.keys(jobData.forecast).filter(
      isCurrentFiscalPeriod
    );

    return {
      jobNumber,
      meta: jobData.meta,
      forecast: jobData.forecast,
      fiscalPeriodDates,
    };
  });
};
export const getBudgetToComplete = (jobOverview: any) => {
  if (!jobOverview)
    return 0;
  let budget = 0;


  for (let key of Object.keys(jobOverview.budgeted.costs)) {
    budget += jobOverview.budgeted.costs[key].directLaborHrs;
  }

  return (budget - (jobOverview.actual.costs.paid.directLaborHrs + jobOverview.actual.costs.unpaid.directLaborHrs));
};


export const getPmName = (jobNumber : string, allJobs : any) => {
  const job = allJobs.find((job : any) => job.jobno === jobNumber);
  if (job) {
    return job.pmname;
  }
  return "-";
}