import { AuthContext } from "@fsg/apps-api-client-react"
import { Modal } from "@fsg/gui-bits"
import { useContext, useRef, useState } from "react"
import { JobFilters } from "../../../../helpers/job-filters"
import {
  CustomRiskProfile,
  CustomRiskProfileSettings,
} from "../../../../types/types"
import {
  createUserSettings,
  prepareProfilesForDB,
  updateUserSettings,
} from "helpers/userSettingsApi"
import TabPane from "./TabPane"
import useAppStore from "store/store"

const baseFilterSettings = {
  tempLaborRatio: "0",
  consUnderbillings: "0",
  budgetAdjustments: "0",
  percentComplete: "0",
  pastDueInvoices: "0",
  pastDueInvoicesAmount: "0",
  actualHrsExceedsBaseline: false,
  forecastHrsExceedsBaseline: false,
  predictedHrsExceedsBaseline: false,
  materialActualOver: false,
  materialBudgetOver: false,
}

const emptyPresets = [
  {
    _id: "1",
    name: `Custom Profile 1`,
    settings: baseFilterSettings,
    isStored: false,
  },
  {
    _id: "2",
    name: `Custom Profile 2`,
    settings: baseFilterSettings,
    isStored: false,
  },
  {
    _id: "3",
    name: `Custom Profile 3`,
    settings: baseFilterSettings,
    isStored: false,
  },
]

interface Props {
  setFilters: (filters: Partial<JobFilters>) => void
  //baselineFilters: JobFilters | null
  anchor: React.RefObject<HTMLButtonElement>
  shown: boolean
  setShown: (value: boolean) => void
}

const CustomProfilesModal = ({
  setFilters,
  anchor,
  shown,
  setShown,
}: Props) => {
  const closeButtons = useRef<HTMLButtonElement[]>([])
  const { user } = useContext(AuthContext)
  const {
    data: userSettingsData,
    setData: setUserSettingsData,
    error,
  } = useAppStore((state) => state.userSettings)
  const [updating, setUpdating] = useState(false)
  const [updateError, setUpdateError] = useState<Error | null>(null)

  const customRiskProfiles = userSettingsData.risk_profiles.length
    ? userSettingsData.risk_profiles.map((profile) => ({
        ...profile,
        isStored: true,
      }))
    : emptyPresets

  const applyFilters = (filters: Partial<JobFilters>) => {
    setFilters(
      {
        ...filters,
        risk: ["high", "moderate", "low"],
      } || {}
    )
  }

  // HANDLERS FOR MONGODB API

  const handleUpdateProfilesRequest = (newProfiles: CustomRiskProfile[]) => {
    return updateUserSettings({
      risk_profiles: prepareProfilesForDB(newProfiles),
    })
  }

  const handleCreateProfilesRequest = (profiles: CustomRiskProfile[]) => {
    return createUserSettings({
      username: user!.email,
      settings: { risk_profiles: prepareProfilesForDB(profiles) },
    })
  }

  const handleCreateAndApply = async (
    profiles: CustomRiskProfile[],
    filtersToApply: CustomRiskProfileSettings
  ) => {
    setUpdating(true)
    try {
      const newUserData = await handleCreateProfilesRequest(profiles)
      setUserSettingsData(newUserData)
      applyFilters(filtersToApply)
    } catch (e) {
      setUpdateError(e as Error)
    }
    setUpdating(false)
  }

  const handleUpdateAndApply = async (
    profiles: CustomRiskProfile[],
    filtersToApply: CustomRiskProfileSettings
  ) => {
    setUpdating(true)
    try {
      const newUserData = await handleUpdateProfilesRequest(profiles)
      setUserSettingsData(newUserData)
      applyFilters(filtersToApply)
    } catch (e) {
      setUpdateError(e as Error)
    }
    setUpdating(false)
  }

  const clearProfilesAndApply = async (profileId: string) => {
    setUpdating(true)
    try {
      const profiles = Array(3).fill({
        settings: baseFilterSettings,
      })
      const newUserData = await handleUpdateProfilesRequest(profiles)
      setUserSettingsData(newUserData)
      applyFilters(baseFilterSettings)
    } catch (e) {
      setUpdateError(e as Error)
    }
    setUpdating(false)
  }

  const tabPane = (
    <section className="custom-filters-form">
      <TabPane
        handleCreateAndApply={handleCreateAndApply}
        handleUpdateAndApply={handleUpdateAndApply}
        clearProfilesAndApply={clearProfilesAndApply}
        baseFilterSettings={baseFilterSettings}
        setShown={setShown}
        closeButtons={closeButtons}
        profiles={customRiskProfiles}
        updating={updating}
        updateError={updateError}
      />
    </section>
  )

  return (
    <Modal
      anchor={anchor}
      closeButton
      defaultShown={shown}
      headerText="Custom Risk Profile"
      customCloseButtons={closeButtons}
      className="custom-filters-modal"
    >
      {/* If user has not saved any profiles, api returns 404, thus no error message necessary */}
      {error ? <div className="error">Error getting profiles</div> : null}
      {tabPane}
    </Modal>
  )
}
export default CustomProfilesModal
