import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import useGeocoder from "../../hooks/useGeocoder";
import { LoadingSpinner } from "@fsg/gui-bits";
import { ReactComponent as MapFallback } from "../../assets/map-fallback.svg";
import {ErrorBoundary} from 'react-error-boundary'

interface Props {
  address: string;
}

const FallBack = () => {
    return (
        <div className="map-fallback">
            <MapFallback />
        </div>
    )
}

const Mapbox = ({ address }: Props) => {
  const { position: coordinates, status, error } = useGeocoder(address);

  if (status === "pending") return <LoadingSpinner />;
  if (!coordinates || status === "rejected" || error || !process.env.REACT_APP_MAPBOX_TOKEN)
    return (
      <FallBack/>
    );

  const [latitude, longitude] = coordinates;
  const tileset = `satellite-v9`;

  return (
      <ErrorBoundary FallbackComponent={FallBack}>
        <Map
          reuseMaps
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            longitude,
            latitude,
            zoom: 14,
          }}
          // style={style}
          mapStyle={`mapbox://styles/mapbox/${tileset}`}
        >
          <Marker latitude={latitude} longitude={longitude} anchor="bottom" />
        </Map>
      </ErrorBoundary>
  );
};

export default Mapbox;
