import { useJobsContext } from "../Dashboard/useJobsContext";

import "./RiskMonitorPage.scss";
import Sidebar from "./Sidebar";
import RiskOverview from "./RiskOverview";
import JobOverview from "../../components/JobOverview/JobOverview";
import { useState } from "react";
import Loading from "../../components/Loading";
import ReactGA from "react-ga4";

const RiskMonitorPage = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'Risk Monitor Page'});
  const {
    jobs,
    filteredJobs,
    error,
    status,
    selectedJobNumbers,
    handleSelectJob,
  } = useJobsContext();

  const [sidebarSearchInput, setSidebarSearchInput] = useState("");

  if (status === "pending" || jobs === undefined || jobs.length === 0)
    return <Loading iconSize="large" />
  if (status === "fulfilled" && !jobs?.length) return <>No jobs found</>;
  if (error) {
    console.error("error fetching job", error);
    return <>Error fetching job data</>;
  }

  const selectedJobs =
    selectedJobNumbers && selectedJobNumbers.length 
      ? selectedJobNumbers.map((jobNumber) => {
          return jobs.find((job) => job.jobno === jobNumber);
        })
      : null;

  const mainView = !selectedJobs ? (
    <RiskOverview jobs={filteredJobs} handleSelectJob={handleSelectJob} />
  ) : (
    <section className="selected-jobs">
      {selectedJobs.map((job) => {
        return job ? <JobOverview job={job} /> : null;
      })}
    </section>
  );

  return (
    <section id="risk-monitor-page">
      <Sidebar
        sidebarSearchInput={sidebarSearchInput}
        setSidebarSearchInput={setSidebarSearchInput}
      />
      {mainView}
    </section>
  );
};

export default RiskMonitorPage;
