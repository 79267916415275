import { StateCreator } from "zustand"
import { Status, JobInterface } from "../types/types"
import { JobFilters } from "../helpers/job-filters"

export interface JobsSlice {
  jobs: {
    jobs?: JobInterface[]
    filters: JobFilters
    setFilters: (filters: Partial<JobFilters>) => void
    setJobs: (jobs: JobInterface[]) => void
    status: Status
    setStatus: (status: Status) => void
    error: Error | null
    setError: (error: Error | null) => void
  }
}

const getInitialFilters = () => {
  const localFilters = localStorage.getItem("fsg-manager/filter-settings");

  if (!localFilters) return {
    jobValue: [0, 999999999999],
    tempLaborRatio: "0",
    consUnderbillings: "0",
    budgetAdjustments: "0",
    percentComplete: "0",
    actualHrsExceedsBaseline: false,
    forecastHrsExceedsBaseline: false,
    predictedHrsExceedsBaseline: false,
    divisions: [],
    managers: [],
    jobTypes: [],
    risk: ["high"],
    pastDueInvoices: "0",
  }

  const filters = JSON.parse(localFilters);

  return filters;
}

export const createJobsSlice: StateCreator<JobsSlice, [], [], JobsSlice> = (set) => ({
  jobs: {
    jobs: [],
    setJobs: (jobs: JobInterface[]) => {
      //const filters = getBaselineJobFilters(jobs, ``)
      set((state) => {
        const filters = state.jobs.filters;
        let jobData : any = {
          jobs: {
            ...state.jobs,
            jobs,
          },
        }

        const jobValuesArray = jobData.jobs.jobs?.map((job : any) => job.atrisk).sort((a: number, b: number) => a - b)
        const lowerJobValue = jobValuesArray?.[0]
        const upperJobValue = jobValuesArray?.[jobValuesArray.length - 1]

        if (filters) {
          jobData.filters = {
            ...filters,
            jobValue: [lowerJobValue, upperJobValue],
          };
        } else {
          jobData.filters = {
            ...getInitialFilters(),
            jobValue: [lowerJobValue, upperJobValue],
          };
        }

        return jobData;
      })
    },

    filters: getInitialFilters(),
    setFilters: (filters: Partial<JobFilters>) => {
      set((state) => {

        const newFilters = {
          ...state.jobs.filters,
          ...filters,
        }

        localStorage.setItem(
          "fsg-manager/filter-settings",
          JSON.stringify(newFilters)
        )

        return { jobs: { ...state.jobs, filters: newFilters } }
      })
    },

    status: "idle" as Status,
    setStatus: (status: Status) =>
      set((state) => ({ jobs: { ...state.jobs, status } })),

    error: null,
    setError: (error: Error | null) =>
      set((state) => ({ jobs: { ...state.jobs, error } })),
  },
})
