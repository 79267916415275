import {
  SpookyTextInput,
  ToggleInput,
} from "@fsg/gui-bits";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";
import "./Sidebar.scss";
import { useJobsContext } from "../../Dashboard/useJobsContext";
import JobsList from "./JobsList";
import useAppStore from "store/store"
import TabPane from "components/TabPane"
import { ReactComponent as StarIcon } from "assets/star.svg"
import { ReactComponent as EyeOff } from "assets/eye-off.svg"
import { ReactComponent as Office } from "assets/office-building.svg"
import { JobInterface } from "types/types"

interface Props {
  sidebarSearchInput: string
  setSidebarSearchInput: (value: string) => void
}

const Sidebar = ({ sidebarSearchInput, setSidebarSearchInput }: Props) => {
  const { jobs, filteredJobs, selectedJobNumbers, handleSelectJob } =
    useJobsContext()
  const { filterMode, setFilterMode, filterModeHistory } = useAppStore(
    (state) => state.dashboard
  )
  const { data: userSettingsData } = useAppStore((state) => state.userSettings)

  const savedJobs = userSettingsData?.job_preferences?.saved || []
  const hiddenJobs = userSettingsData?.job_preferences?.hidden || []

  const showAllJobs = filterMode === "default"

  if (!jobs) return <></> // ! need an actual fallback UI for this

  const shownJobs = showAllJobs ? jobs : filteredJobs

  const selectedJobs = shownJobs.filter((job) => {
    return selectedJobNumbers?.includes(job.jobno)
  })

  // Jobs list data for primary jobs tab.
  let foundJobs = shownJobs.filter((job) => {
    const jobNameMatches = job.jobnm
      .toLowerCase()
      .match(sidebarSearchInput.toLowerCase())
    const jobNumberMatches = job.jobno
      .toLowerCase()
      .match(sidebarSearchInput.toLowerCase())
    const jobIsNotHidden = !hiddenJobs?.includes(job.jobno)

    return (jobNameMatches || jobNumberMatches) && jobIsNotHidden
  })

  const savedJobsList = jobs.filter(({ jobno }) => savedJobs?.includes(jobno))
  const hiddenJobsList = jobs.filter(({ jobno }) => hiddenJobs?.includes(jobno))

  // dedupe selectedJob from foundJobs
  if (selectedJobs.length) {
    foundJobs = foundJobs.filter((job) => job.jobno !== selectedJobs[0].jobno)
  }

  const toggleShowAllJobs = () => {
    // setting filterMode to prev value in history only if current value is 'default' because that is when the "toggle" functionality provided by filterModeHistory is necessary
    // when the user is switching between 'high' and 'custom' filter modes, there should be no interaction with the 'All Jobs' toggle input
    if (filterMode === "default") {
      setFilterMode(filterModeHistory.prev)
      return
    }
    setFilterMode("default")
  }

  const JobsListHOC = ({ list }: { list: JobInterface[] }) => (
    <JobsList
      list={list}
      onSelect={handleSelectJob}
      selectedJobs={selectedJobs}
    />
  )

  return (
    <section className="jobs-sidebar-wrapper">
      <div id="actions-bar">
        <span>
          {showAllJobs ? (
            <>
              Showing <strong>{jobs?.length}</strong> jobs
            </>
          ) : (
            <>
              <strong>{filteredJobs.length} </strong>
              results of {jobs?.length}
            </>
          )}
        </span>
        <ToggleInput
          label="All jobs"
          checked={showAllJobs}
          onChange={toggleShowAllJobs}
        />
        <SpookyTextInput
          className="spooky-text-input"
          icon={SearchIcon}
          value={sidebarSearchInput}
          placeholder="Job name or number"
          onChange={(e) => setSidebarSearchInput(e.target.value)}
          onClose={(e) => {
            e.stopPropagation()
            setSidebarSearchInput("")
          }}
          width="100%"
        />
      </div>
      <div id="jobs-sidebar">
        <TabPane
          tabs={[
            {
              label: "Jobs",
              icon: <Office />,
              content: <JobsListHOC list={foundJobs} />,
            },
            {
              label: "Saved",
              icon: <StarIcon />,
              content: savedJobsList.length ? (
                <JobsListHOC list={savedJobsList} />
              ) : (
                <div className="empty-list-message">
                  <p>No saved jobs</p>
                </div>
              ),
            },
            {
              label: "Hidden",
              icon: <EyeOff />,
              content: hiddenJobsList.length ? (
                <JobsListHOC list={hiddenJobsList} />
              ) : (
                <div className="empty-list-message">
                  <p>No hidden jobs</p>
                </div>
              ),
            },
          ]}
        />
      </div>
    </section>
  )
}

export default Sidebar;
