import { useEffect, useState } from "react"
import { request } from "../../api"
import { endpoints } from "../../constants/endpoints";

const useJobMeta = (jobNumber: string | undefined) => {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!jobNumber) return

    setLoading(true)

    const fetchBudgets = async () => {
      try {
        const data: any = await request({
          path: `${endpoints.info}?jobno=${jobNumber}`,
        })
        setData(data.info[0] || [])
      } catch (e) {
        setError(e as Error)
      }

      setLoading(false)
    }

    fetchBudgets()
  }, [jobNumber])

  return { data, loading, error }
}

export default useJobMeta
