import { request } from "api"
import { endpoints } from "constants/endpoints"
import { useEffect, useState } from "react"
import { JobInterface, JobRiskScorecardInterface, ScorecardMetric, Status } from "types/types"

const getMetrics = (scorecard: JobRiskScorecardInterface) => {
  const { metrics } = scorecard
  const map = new Map<string, ScorecardMetric>()
  metrics?.forEach((metric) => map.set(metric.key, metric))
  return map
}

// can use as fallback data for server errors
const initialData: JobRiskScorecardInterface = {
  score: undefined,
  max: undefined,
  metrics: [],
}

const useRiskScoredcard = (job: JobInterface) => {
  const [data, setData] = useState<JobRiskScorecardInterface>(initialData)
  const [status, setStatus] = useState<Status>("idle")
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!job) return
    setStatus("pending")
    const fetchData = async () => {
      try {
        const data: any = await request({
          method: "POST",
          path: `${endpoints.risk_scorecard}`,
          requestOptions: {
            body: JSON.stringify(job),
          },
        })
        setData(data)
        setStatus("fulfilled")
      } catch (e) {
        setData(initialData)
        setError(e as Error)
        setStatus("rejected")
      }
    }

    fetchData()
  }, [job])

  return { data, status, error, getMetrics }
}

export default useRiskScoredcard
