import { SelectInput } from "@fsg/gui-bits";
import useDivisions from "../../hooks/useDivisions";

interface Props {
  division: string;
  setDivision: (div: string) => void;
}

const DivisionSelect = ({ division, setDivision }: Props) => {
  const { data: divisions, status: divisionsStatus } = useDivisions();

  const divisionOptions = divisions
    .filter((division) => {
      // exclude MDC and corporate divisions from options
      const excluded = ["99", "912", "930", "970"];
      return !excluded.includes(division.number);
    })
    .map((division) => {
      return {
        label: `${division.number} - ${division.name}`,
        value: division.number,
      };
    });

  return (
    <SelectInput
      className="select-input division-input"
      loading={divisionsStatus === "pending"}
      placeholder="Division"
      options={divisionOptions}
      value={division}
      onChange={(e) => {
        setDivision(e.target.value);
      }}
    />
  );
};
export default DivisionSelect;
