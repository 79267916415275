import { AuthContext } from "@fsg/apps-api-client-react"
import { createUserSettings, updateUserSettings } from "helpers/userSettingsApi"
import { useContext } from "react"
import useAppStore from "store/store"

const useToggleJob = (jobNumber: string) => {
  const { user } = useContext(AuthContext)

  const {
    data: { job_preferences, _id },
    setData: setUserSettingsData,
  } = useAppStore((state) => state.userSettings)

  const { saved: savedJobs, hidden: hiddenJobs } = job_preferences

  // handles sending the changes to the server
  async function updateJobPreferences({
    saved,
    hidden,
  }: {
    saved?: string[]
    hidden?: string[]
  }) {
    if (!saved && !hidden)
      throw new Error(
        `Properties "saved" and "hidden" cannot both be undefined`
      )
    // Prepare the data;
    // since both "saved" and "hidden" params are optional, if one is undefined, get its value from store or set to empty array.
    const data = {
      saved: saved ? saved : job_preferences.saved || [],
      hidden: hidden ? hidden : job_preferences.hidden || [],
    }
    try {
      // Whether to update (PUT) or create (POST) will depend on if the user has created user settings, either by custom risk filters or job preferences, etc.
      // POST requires a username which comes from auth context which currently must be called from within a hook, otherwise this logic could be in the udpate handler function itself (`updateUserSettings`) and it could handle creating new data too. 
      if (_id !== undefined) {
        const newSettings = await updateUserSettings({ job_preferences: data })
        setUserSettingsData(newSettings)
      } else {
        const newSettings = await createUserSettings({
          username: user!.email,
          settings: { job_preferences: data },
        })
        setUserSettingsData(newSettings)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const isSaved = savedJobs?.includes(jobNumber)
  const isHidden = hiddenJobs?.includes(jobNumber)

  // the toggle functions handle all cases: empty state, adding, and removing jobs from saved/hidden jobs lists
  const toggleSavedJob = () => {
    // if no saved jobs data has been initialized, simply set a new array with the job number
    if (savedJobs === undefined) {
      updateJobPreferences({ saved: [jobNumber] })
      return
    }
    // if savedJobs already contains the job number, remove it
    if (savedJobs?.includes(jobNumber)) {
      updateJobPreferences({
        saved: savedJobs?.filter((current) => current !== jobNumber),
      })
      return
    }
    // else, add the job number to savedJobs
    const newSavedJobs = [...savedJobs!, jobNumber]
    updateJobPreferences({ saved: newSavedJobs })
  }

  // same functionality for hidden jobs
  const toggleHiddenJob = () => {
    if (hiddenJobs === undefined) {
      updateJobPreferences({ hidden: [jobNumber] })
      return
    }
    if (hiddenJobs?.includes(jobNumber)) {
      updateJobPreferences({
        hidden: hiddenJobs?.filter((current) => current !== jobNumber),
      })
      return
    }
    const newHiddenJobs = [...hiddenJobs!, jobNumber]
    updateJobPreferences({ hidden: newHiddenJobs })
  }

  return { toggleSavedJob, toggleHiddenJob, isSaved, isHidden }
}

export default useToggleJob
