import { SubHeaderText } from "@fsg/gui-bits"
import "./Manpower.scss"
import DivisionManpowerTable from "./DivisionManpowerTable"
import { useCallback, useEffect, useState } from "react"
import { request } from "../../api"
import DivisionSelect from "./DivisionSelect"
import FallbackUI from "./Fallback/FallbackUI"
import { endpoints } from "../../constants/endpoints"
import { ReactComponent as Landscape } from "../../assets/landscape.svg"
import Loading from "../../components/Loading"
import { useSearchParams } from "react-router-dom"
//import { useJobsContext } from "../Dashboard/useJobsContext"
import { DataType } from "./Manpower.types"
import ReactGA from "react-ga4";

const buildData = (data: any) => {
  const anticipated = data.anticipated
  delete data["serialize_time"]
  delete data["key_iterations"]
  delete data["anticipated"]
  const transformedData = data
  return { jobs: transformedData, anticipated }
}

const DivisionManpower = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [division, setDivision] = useState(searchParams.get("div") || "")
  const [data, setData] = useState<DataType | null>(null)
  const [error, setError] = useState<any | null>(null)
  const [status, setStatus] = useState("idle")
  //const jobsContext = useJobsContext();

  const getDataByDivision = useCallback(async (div: string) => {
    setStatus("pending")
    try {
      const response = await request({
        path: `${endpoints.manpower}?div=${div}`,
      });
      
      const transformedData = buildData(response)
      setData(transformedData)
      setStatus("fulfilled")
      setError(null)
    } catch (e) {
      setError(e)
      setStatus("rejected")
      setData(null)
      console.error(e)
    }
  }, [])

  useEffect(() => {
    const division = searchParams.get("div")
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Manpower | ${division ? `Div ${division} |` : "" } FSG`});
    if (division && !data) {
      getDataByDivision(division)
    }
  }, [data, getDataByDivision, searchParams])

  const handleSelectDivision = (div: string) => {
    setDivision(div)
    getDataByDivision(div)
    setSearchParams(`div=${div}`)
  }

  return (
    <div id="division-manpower">
      <div className="header">
        <SubHeaderText className="sub-header">
          Division Manpower Forecast
        </SubHeaderText>
        <div className="controls">
          <DivisionSelect
            division={division}
            setDivision={handleSelectDivision}
          />
        </div>
      </div>
      {error ? (
        <div className="error">Error: {JSON.stringify(error)}</div>
      ) : null}
      {status === "pending" ? (
        <Loading iconSize="large" />
      ) : division && data ? (
        <div className="table-container">
          <DivisionManpowerTable
            data={data}
            fallback={
              <FallbackUI
                header={`No Forecasted Jobs for Division ${division}`}
              >
                <p>
                  Select another division to view Division Manpower Forecast
                </p>
                <Landscape />
              </FallbackUI>
            }
          />
        </div>
      ) : null}
    </div>
  )
}

export default DivisionManpower
