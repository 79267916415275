import AutoSizer from "react-virtualized-auto-sizer"
import { FixedSizeList as List } from "react-window"
import { JobInterface } from "../../../../types/types"
import Flag from "../../../../components/Flag"
import formatCurrency from "../../../../utils/formatCurrency"
import { Button, ToolTip } from "@fsg/gui-bits"
import { useRef } from "react"
import { ReactComponent as CloseIcon } from "../../../../assets/x.svg"

const SelectedButton = ({
  job,
  onSelect,
}: {
  job: any
  onSelect: (jobno: string) => void
}) => {
  const selectedButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <Button
      buttonType="secondary"
      key={job.jobno}
      style={{
        position: "sticky",
        width: "100%",
        padding: "0px",
        border: "2px solid white",
        borderRadius: "0px",
      }}
      onClick={() => onSelect(job.jobno)}
      className="selected-button"
      withIcon
      ref={selectedButtonRef}
    >
      <div className="job-card wrapper selected">
        <div className="details">
          <div>
            <p>Currently selected</p>
            <h4>{job.jobnm}</h4>
          </div>
          <CloseIcon />
        </div>
      </div>
      <ToolTip anchor={selectedButtonRef}>Click to close</ToolTip>
    </Button>
  )
}

const JobButton = ({
  job,
  onSelect,
  style,
}: {
  job: any
  onSelect: (jobno: string) => void
  style: any
}) => {
  return (
    <Button
      buttonType="secondary"
      key={job.jobno}
      style={{
        ...style,
        padding: "0px",
        border: "2px solid white",
        borderRadius: "0px",
      }}
      onClick={() => onSelect(job.jobno)}
    >
      <div className="job-card wrapper">
        <div className="title">
          <h4>{job.jobnm}</h4>
          <Flag
            type={job.risk === "high" ? "high" : "moderate"}
            size="small"
            text={job.risk === "high" ? "high" : "custom"}
          />
        </div>
        <p className="customer-number">Job #{job.jobno}</p>
        <div className="details">
          <p>{formatCurrency.compact(job.atrisk)}</p>
          <p> | {job.pmname}</p>
        </div>
      </div>
    </Button>
  )
}

const JobsList = ({
  list,
  onSelect,
  selectedJobs,
}: {
  list: JobInterface[]
  onSelect: (jobNumber: string) => void
  selectedJobs?: JobInterface[]
}) => {
  return (
    <>
      {selectedJobs?.map((job) => (
        <SelectedButton job={job} onSelect={onSelect} />
      ))}
      <AutoSizer className="auto-sizer">
        {({ height, width }) => (
          <List
            height={height}
            itemCount={list.length}
            itemSize={122}
            width={width}
          >
            {({ index, style }) => (
              <JobButton job={list[index]} onSelect={onSelect} style={style} />
            )}
          </List>
        )}
      </AutoSizer>
    </>
  )
}

export default JobsList
