import React, { PropsWithChildren, useState } from "react"
import "./TabPane.scss"
interface Tab {
  label: string | React.ReactNode
  content: React.ReactNode
  icon?: any
}

interface TabButton extends PropsWithChildren {
  index: number
  withIcon?: boolean
}

interface Props {
  tabs: Tab[]
}

const TabPane = ({ tabs }: Props) => {
  const [activeTab, setActiveTab] = useState(0)
  const indexedTabs = tabs.map((tab, index) => ({ ...tab, index }))

  const TabButton = ({ index, withIcon, children }: TabButton) => {
    return (
      <button
        onClick={() => setActiveTab(index)}
        className={`tab-header-button ${activeTab === index ? "active" : ""} ${
          withIcon ? "has-icon" : ""
        }`}
      >
        {children}
      </button>
    )
  }

  return (
    <div className="tab-panes">
      <div className="tabs">
        {indexedTabs.map(({ label, index, icon }) => (
          <TabButton key={index} index={index} withIcon={!!icon}>
            {label}
            {icon || null}
          </TabButton>
        ))}
      </div>
      <div className="tab-pane">{indexedTabs[activeTab].content}</div>
    </div>
  )
}
export default TabPane
