import { KPICard, SubHeaderText } from "@fsg/gui-bits"
import { ReactComponent as Users } from "assets/users.svg"
import { ReactComponent as OfficeBuilding } from "assets/office-building.svg"
import { ReactComponent as AlertTriangle } from "assets/alert-triangle.svg"
import {
  JobInterface,
  JobRiskScorecardInterface,
  ScorecardMetric,
} from "types/types"
import "./RiskKPICards.scss"
import formatCurrency from "utils/formatCurrency"

const formatDollar = formatCurrency.standard


interface Scorecard {
  scorecard: JobRiskScorecardInterface
  metrics: Map<string, ScorecardMetric>
}

interface Props {
  job: JobInterface
  scorecard: Scorecard | undefined
}

interface KPICards {
  label: string
  primary: any
  primaryColor?: "red" | "green" | "black"
  secondary?: string
  secondaryColor?: "red" | "green" | "black"
  secondaryClassname?: string
  icon?: any
}

const RiskKPICards = ({ job, scorecard }: Props) => {
  const actualHrsCrossedBaseline = job.actual_labor_hrs > job.contract_labor_hrs
  const actualHrsCrossedContractAndBudget = job.actual_labor_hrs > job.budgeted_labor_hrs && job.actual_labor_hrs > job.contract_labor_hrs;
  const materialActualCrossedBaseline = job["mat-actual"] > job["mat-base"]

  const getRiskPoints = (key: string) => {
    if (scorecard?.metrics.get(key) === undefined) return "-"
    return `${scorecard?.metrics.get(key)?.score} / ${
      scorecard?.metrics.get(key)?.max
    }`
  }

  const actualCosts: KPICards[] = [
    {
      label: "Actual Hours Crossed Contract Total Hours",
      primary: (
        <div className="kpi-card-primary-wrapper">
          <span>{actualHrsCrossedBaseline ? "Yes" : "No"}</span>{" "}
          <span>
            <span className="current-value">
              {job.actual_labor_hrs.toLocaleString()}{" "}
            </span>
            <span className="reference-value">
              / {job.contract_labor_hrs.toLocaleString()} Hours
            </span>
          </span>
        </div>
      ),
      primaryColor: actualHrsCrossedBaseline ? "red" : undefined,
      icon: actualHrsCrossedBaseline ? AlertTriangle : Users,
      secondary: `Risk points: ${getRiskPoints(
        "actual_hours_crossed_baseline"
      )}`,
      secondaryClassname: "kpi-card-secondary",
    },

    {
      label: "Material Actual Crossed Contract Total Cost",
      primary: (
        <div className="kpi-card-primary-wrapper">
          <span>{materialActualCrossedBaseline ? "Yes" : "No"}</span>{" "}
          <span className="current-value">
            {formatDollar(job["mat-actual"])}
          </span>
          <span className="reference-value">
            {" "}
            / {formatDollar(job["mat-base"])}
          </span>
        </div>
      ),
      primaryColor: materialActualCrossedBaseline ? "red" : undefined,
      secondary: `Risk points: ${getRiskPoints(
        "material_actual_crossed_baseline"
      )}`,
      icon: materialActualCrossedBaseline ? AlertTriangle : OfficeBuilding,
      secondaryClassname: "kpi-card-secondary",
    },
    {
        label: "Actual Hours Crossed Contract & Budget Hours",
        icon: actualHrsCrossedContractAndBudget ? AlertTriangle : OfficeBuilding,
        primaryColor: actualHrsCrossedContractAndBudget ? "red" : undefined,
        primary: (
          <div className="kpi-card-primary-wrapper">
            <span>
              {actualHrsCrossedContractAndBudget ? "Yes" : "No"}
            </span>
            <span className="current-value">
              {job.actual_labor_hrs.toLocaleString()}{" "}
            </span>
            <span className="reference-value">
              / {job.contract_labor_hrs.toLocaleString()} Hours
              / {job.budgeted_labor_hrs.toLocaleString()} Hours
            </span>
          </div>
        ),
        secondary: `Risk points: ${getRiskPoints("labor_over_budget_over_contract")}`,
        secondaryClassname: "kpi-card-secondary",
    }
  ]

  const laborBudgetCrossedBaseline =
    job.budgeted_labor_hrs > job.contract_labor_hrs
  const materialBudgetCrossedBaseline = job["mat-budget"] > job["mat-base"]

  const budgetedCosts: KPICards[] = [
    {
      label: "PM Labor Budget Crossed Contract Total Hours",
      primary: (
        <div className="kpi-card-primary-wrapper">
          <span>{laborBudgetCrossedBaseline ? "Yes" : "No"}</span>{" "}
          <span>
            <span className="current-value">
              {job.budgeted_labor_hrs.toLocaleString()}
            </span>
            <span className="reference-value">
              {" "}
              / {job.contract_labor_hrs.toLocaleString()} Hours
            </span>
          </span>
        </div>
      ),
      primaryColor: laborBudgetCrossedBaseline ? "red" : undefined,
      secondary: `Risk points: ${getRiskPoints(
        "labor_budget_crossed_baseline"
      )}`,
      icon: laborBudgetCrossedBaseline ? AlertTriangle : Users,
      secondaryClassname: "kpi-card-secondary",
    },
    {
      label: "PM Material Budget Crossed Contract Total Cost",
      primary: (
        <div className="kpi-card-primary-wrapper">
          <span>{materialBudgetCrossedBaseline ? "Yes" : "No"}</span>{" "}
          <span>
            <span className="current-value">
              {formatDollar(job["mat-budget"])}
            </span>
            <span className="reference-value">
              {" "}
              / {formatDollar(job["mat-base"])}
            </span>
          </span>
        </div>
      ),
      primaryColor: materialBudgetCrossedBaseline ? "red" : undefined,
      secondary: `Risk points: ${getRiskPoints(
        "material_budget_crossed_baseline"
      )}`,
      icon: materialBudgetCrossedBaseline ? AlertTriangle : OfficeBuilding,
      secondaryClassname: "kpi-card-secondary",
    },
  ]

  const laborForecastCrossedBaseline = job["mpwr-hrs"] > job.contract_labor_hrs
  const laborPredictionCrossedBaseline =
    job.predicted_labor_hrs > job.contract_labor_hrs

  const forecastAndPredicted: KPICards[] = [
    {
      label: "Labor Forecast Crossed Contract Total Hours",
      primary: (
        <div className="kpi-card-primary-wrapper">
          <span>{laborForecastCrossedBaseline ? "Yes" : "No"}</span>{" "}
          <span>
            <span className="current-value">
              {job["mpwr-hrs"].toLocaleString()}
            </span>
            <span className="reference-value">
              {" "}
              / {job.contract_labor_hrs.toLocaleString()} Hours
            </span>
          </span>
        </div>
      ),
      primaryColor: laborForecastCrossedBaseline ? "red" : undefined,
      secondary: `Risk points: ${getRiskPoints(
        "labor_forecast_crossed_baseline"
      )}`,
      icon: laborForecastCrossedBaseline ? AlertTriangle : Users,
      secondaryClassname: "kpi-card-secondary",
    },
    {
      label: "Predicted Hours Crossed Contract Total Hours",
      primary: (
        <div className="kpi-card-primary-wrapper">
          <span>{laborPredictionCrossedBaseline ? "Yes" : "No"}</span>{" "}
          <span>
            <span className="current-value">
              {job.predicted_labor_hrs.toLocaleString()}
            </span>
            <span className="reference-value">
              {" "}
              / {job.contract_labor_hrs.toLocaleString()}
            </span>
          </span>
        </div>
      ),
      primaryColor: laborPredictionCrossedBaseline ? "red" : undefined,
      secondary: `Risk points: ${getRiskPoints(
        "predicted_hours_crossed_baseline"
      )}`,
      icon: laborPredictionCrossedBaseline ? AlertTriangle : Users,
      secondaryClassname: "kpi-card-secondary",
    },
  ]

  return (
    <>
      <SubHeaderText>Actual Costs</SubHeaderText>
      <div className="row">
        {actualCosts.map((props) => {
          return <KPICard key={props.label} className="hero-card" {...props} />
        })}
      </div>
      <div className="spacer"></div>
      <SubHeaderText>PM Budgeted Costs</SubHeaderText>
      <div className="row">
        {budgetedCosts.map((props) => {
          return <KPICard key={props.label} className="hero-card" {...props} />
        })}
      </div>
      <div className="spacer"></div>
      <SubHeaderText>Forecasted & Predicted Costs</SubHeaderText>
      <div className="row">
        {forecastAndPredicted.map((props) => {
          return <KPICard key={props.label} className="hero-card" {...props} />
        })}
      </div>
    </>
  )
}
export default RiskKPICards
