import { Button, ButtonGroup, LoadingSpinner, PopOver, TextInput } from "@fsg/gui-bits"
import { useEffect, useRef, useState } from "react"
import { getFilteredJobs, JobFilters } from "../../../../helpers/job-filters"
import useAppStore from "../../../../store/store"
import formatCurrency from "../../../../utils/formatCurrency"
import "./JobValueRange.scss"

interface Props {
  filtersState: JobFilters | null
  setFilters: (filters: Partial<JobFilters>) => void
}


const JobValueRange = ({ filtersState, setFilters }: Props) => {
  const {
    jobs: unfilteredJobs,
    filters,
    status,
    jobs: allJobs,
  } = useAppStore((state) => state.jobs)
  const jobs = getFilteredJobs(unfilteredJobs || [], filters)
  const popoverRef = useRef<HTMLButtonElement>(null)

  const allCosts: number[] = allJobs!
    .map((job) => job.atrisk)
    .sort((a, b) => a - b)

  const [allMin] = useState(0)
  const [allMax, setMax] = useState(allCosts[allCosts.length - 1])
  const [jobMin, setJobMin] = useState(filtersState?.jobValue?.[0] || 0);
  const [jobMax, setJobMax] = useState(filtersState?.jobValue?.[1] || 0);
  

  useEffect(() => {
    const max = allCosts[allCosts.length - 1];
    setMax(max)
  }, [allCosts])

  if (
    !jobs ||
    jobMin === null ||
    jobMax === null ||
    jobMin === undefined ||
    jobMax === undefined
  )
    return <LoadingSpinner />
  if (status === "rejected") return <p>Error</p>

  return (
    <div>
      {status !== "pending" ? (
        <>
          <ButtonGroup className={allMin !== jobMin || allMax > jobMax ? "applied-filter" : ``}>
            <Button buttonType="secondary" ref={popoverRef}>
              Job Value: {jobMin === 0 ? 0 : `${formatCurrency.compact(jobMin)}`} -{" "}
              {jobMax > allMax ? `${formatCurrency.compact(allMax)}+` : `${formatCurrency.compact(jobMax)}`}
            </Button>
            {(allMin !== jobMin || allMax > jobMax) && (
              <Button buttonType="secondary" onClick={() => {
                setJobMin(allMin)
                setJobMax(allMax)
                setFilters({ jobValue: [allMin, allMax] })
              }}>&nbsp;&times;&nbsp;</Button>)}
          </ButtonGroup>
          <PopOver position="bottom" anchor={popoverRef}>
            <div className="job-values">
              <TextInput leftLabel="$" type="text" value={jobMin.toLocaleString()} onChange={(e) => {
                const value = parseInt(e.target.value.replace(/\D/g, ``), 10) || 0;
                setJobMin(value)
                setFilters({ jobValue: [value, jobMax] })
              }} />
              <span> - </span>
              <TextInput leftLabel="$" type="text" value={jobMax > allMax ? allMax.toLocaleString() : jobMax.toLocaleString()} onChange={(e) => {
                const value = parseInt(e.target.value.replace(/\D/g, ``), 10) || 0;
                setJobMax(value)
                setFilters({ jobValue: [jobMin, value] })
              }} />
            </div>
          </PopOver>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
}
export default JobValueRange
