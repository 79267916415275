import { UserSettingsInterface, Status } from "types/types"
import { StateCreator } from "zustand"

export interface UserSettingsSlice {
  userSettings: {
    data: UserSettingsInterface
    setData: (data: Partial<UserSettingsInterface> | null) => void
    status: Status
    setStatus: (status: Status) => void
    error: Error | null
    setError: (error: Error | null) => void
  }
}

const initialData = {
  _id: undefined,
  user_name: undefined,
  risk_profiles: [],
  job_preferences: {
    saved: [],
    hidden: [],
  },
}

export const createUserSettingsSlice: StateCreator<
  UserSettingsSlice,
  [],
  [],
  UserSettingsSlice
> = (set) => ({
  userSettings: {
    data: initialData,
    setData: (data: Partial<UserSettingsInterface> | null) => {
      set((state) => {
        return {
          userSettings: {
            ...state.userSettings,
            data:
              data === null
                ? initialData
                : { ...state.userSettings.data, ...data },
          },
        }
      })
    },
    status: "idle",
    setStatus: (status) =>
      set((state) => ({
        userSettings: { ...state.userSettings, status },
      })),
    error: null,
    setError: (error) =>
      set((state) => ({
        userSettings: { ...state.userSettings, error },
      })),
  },
})

export default createUserSettingsSlice
