import {
  FinancialText,
  LoadingSpinner,
  NumberText,
  Table,
  TableCell,
  TableColumn,
  TableHeader,
} from "@fsg/gui-bits"
import useDivisions from "../../hooks/useDivisions"
import { JobInterface } from "../../types/types"

interface Props {
  data: JobInterface[]
  loading: boolean
}

interface DivisionSummary {
  division: any
  jobsAtRisk: any
  tempLaborRatio: any
  atRiskAmount: any
  consecutiveUnderbillings: any
  currentUnderbilled: any
  baselineAdjustments: any
}

const sumValues = (values: number[]) => {
  return values?.reduce((sum, value) => sum + value, 0) || 0
}

const RiskAssessmentTable = ({ data, loading }: Props) => {
  const { data: divisions, status, error } = useDivisions()

  if (status === "pending") return <LoadingSpinner />
  if (!divisions.length && status === "fulfilled")
    return <p>No divisions found</p>
  if (error) return <p>An error has occurred</p>

  const dataByDivisions = divisions.length
    ? divisions.map((division) => {
        const jobs = data.filter((data) => data.div.includes(division.number))
        const jobsAtRisk = jobs?.length

        return {
          division: division.number,
          jobsAtRisk,
          tempLaborRatio: jobs.length
            ? (
                sumValues(jobs?.map((job) => job.thrpct)) / jobs?.length
              ).toFixed(2)
            : "N/A",
          atRiskAmount: sumValues(jobs?.map((job) => job.atrisk)),
          consecutiveUnderbillings: sumValues(
            jobs?.map((job) => job["cons-undrbil"])
          ),
          currentUnderbilled: sumValues(jobs?.map((job) => job.undbill || 0)),
          baselineAdjustments: sumValues(jobs?.map((job) => job["pm-adjs-ct"])),
        }
      })
    : []

  return (
    <>
      <TableHeader size="comfortable" width={300}>
        <strong>Risk Assessment Leaderboard</strong>
      </TableHeader>
      <Table<DivisionSummary>
        height="600px"
        loading={loading}
        data={dataByDivisions}
      >
        <TableColumn<DivisionSummary>
          label="Div"
          accessor={({ division }) => `${division}`}
          width={60}
        >
          <TableCell
            valueComponent={NumberText}
            getValueProps={() => ({
              decimals: 0,
            })}
          />
        </TableColumn>
        <TableColumn<DivisionSummary>
          label="Jobs at Risk"
          accessor={({ jobsAtRisk }) => `${jobsAtRisk}`}
          width={100}
        >
          <TableCell
            valueComponent={NumberText}
            getValueProps={() => ({
              decimals: 0,
            })}
          />
        </TableColumn>
        <TableColumn<DivisionSummary>
          label="Temp Labor"
          accessor={({ tempLaborRatio }) =>
            tempLaborRatio !== "N/A" ? `${tempLaborRatio} %` : "N/A"
          }
          width={100}
        >
          <TableCell valueComponent={NumberText} />
        </TableColumn>
        <TableColumn<DivisionSummary>
          label="At Risk Contract $"
          accessor={({ atRiskAmount }) => `${atRiskAmount}`}
          width={150}
        >
          <TableCell
            valueComponent={FinancialText}
            getValueProps={() => ({
              decimals: 2,
            })}
          />
        </TableColumn>
        <TableColumn<DivisionSummary>
          label="Current $ Underbilled"
          accessor={({ currentUnderbilled }) => `${currentUnderbilled}`}
          width={150}
        >
          <TableCell
            valueComponent={FinancialText}
            getValueProps={() => ({
              decimals: 2,
            })}
          />
        </TableColumn>
        <TableColumn<DivisionSummary>
          label="Baseline Adjustments"
          accessor={({ baselineAdjustments }) => `${baselineAdjustments}`}
          width={150}
        >
          <TableCell
            valueComponent={NumberText}
            getValueProps={() => ({
              decimals: 0,
            })}
          />
        </TableColumn>
      </Table>
    </>
  )
}

export default RiskAssessmentTable
