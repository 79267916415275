import { LoadingSpinner } from "@fsg/gui-bits"
import { useParams } from "react-router-dom"
import JobOverview from "../../components/JobOverview"
import useAppStore from "../../store/store"
import ReactGA from "react-ga4";

const JobOverviewPage = () => {
  const { jobNumber } = useParams()
  const { jobs, status } = useAppStore((state) => state.jobs)

  const job = jobs?.find(
    (j) => j.jobno.toLowerCase() === jobNumber?.toLowerCase()
  )
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `Job Overview | ${job?.jobno} | FSG`});

  if (status === "pending" || status === "idle") {
    return <LoadingSpinner />
  }

  if (!job) return <p>Job not found</p>

  return <JobOverview job={job} />
}

export default JobOverviewPage
