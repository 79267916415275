import { DateText, Table, TableCell, TableColumn } from "@fsg/gui-bits";
import { PerformanceInterface as DataType } from "../../../types/types";
import {ErrorBoundary} from "react-error-boundary";
import FallBack from "../../ErrorFallBack";

interface Props {
  data?: DataType[];
  loading: boolean;
}

const BaselineTable = ({ data, loading }: Props) => {
  const columns = [
    {
      label: "Fiscal Period",
      property: "fiscal_date",
      width: 200,
      tableCell: <TableCell valueComponent={DateText} getValueProps={() => ({ hideTime: true })} />,
    },
    {
      label: "OE",
      property: "oe_hrs",
      width: 75,
    },
    {
      label: "CO",
      property: "co_hrs",
      width: 75,
    },
    {
      label: "CH",
      property: "ch_hrs",
      width: 75,
    },
    {
      label: "PM",
      property: "pm_hrs",
      width: 75,
    },
    {
      label: "WC",
      property: "wc_hrs",
      width: 75,
    },
    {
      label: "Forecast Hours",
      property: "forecast_hrs",
      width: 150,
    },
    {
      label: "Forecast Amount",
      property: "forecast_amt",
      width: 150,
    },
    {
      label: "Forecast Baseline",
      property: "baseline_forecast_hrs",
      width: 150,
    },
    {
      label: "Forecast Baseline Amount",
      property: "baseline_forecast_amt",
      width: 150,
    },
    {
      label: "Total Baseline",
      property: "baseline_total_hrs",
      width: 150,
    },
    {
      label: "Total Baseline Amount",
      property: "baseline_total_amt",
      width: 150,
    },
    {
      label: "Contract Baseline",
      property: "baseline_contract_hrs",
      width: 150,
    },
    {
      label: "Contract Baseline Amount",
      property: "baseline_contract_amt",
      width: 150,
    },
    {
      label: "Actual Hours",
      property: "baseline_actual_hrs",
      width: 150,
    },
    {
      label: "Actual Amount",
      property: "baseline_actual_amt",
      width: 150,
    },
  ];

  return (
      <ErrorBoundary FallbackComponent={FallBack}>
        <Table<DataType>
          data={data || []}
          loading={loading}
          title="Baseline Performance"
        >
          {columns.map(({ property, label, tableCell, width }) => (
            <TableColumn<DataType>
              key={label}
              label={label}
              width={width || 150}
              accessor={(datum: { [key: string]: any }) =>
                `${datum[property] || ""}`
              }
            >
              {tableCell}
            </TableColumn>
          ))}
        </Table>
      </ErrorBoundary>
  );
};
export default BaselineTable;
