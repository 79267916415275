import { HeaderText } from "@fsg/gui-bits"
import { useJobsContext } from "../Dashboard/useJobsContext"
import RiskAssessmentTable from "../RiskMonitor/RiskAssessmentTable"
import ReactGA from "react-ga4";

const LeaderboardPage = () => {
  const { filteredJobs, status } = useJobsContext()
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: 'Leaderboard Page'});
  return (
    <section id="leaderboard-page">
      <HeaderText>Risk Assessment Leaderboard</HeaderText>
      <RiskAssessmentTable data={filteredJobs} loading={status === "pending"} />
    </section>
  )
}
export default LeaderboardPage
