import { Button } from "@fsg/gui-bits"
import "./HideJob.scss"
import { ReactComponent as EyeOn } from "assets/eye.svg"
import { ReactComponent as EyeOff } from "assets/eye-off.svg"
import useToggleJob from "hooks/useToggleJob"

interface Props {
  jobNumber: string
}
const HideJob = ({ jobNumber }: Props) => {
  const { toggleHiddenJob, isHidden } = useToggleJob(jobNumber)

  return (
    <Button
      withIcon
      onClick={toggleHiddenJob}
      buttonType="ghost"
      className="hide-job-button"
    >
      {isHidden ? (
        <EyeOff className="eye-off" />
      ) : (
        <EyeOn className="eye-on" />
      )}
    </Button>
  )
}
export default HideJob
