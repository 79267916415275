import React, { useContext, useEffect, useState } from "react"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"
import { NavItem, UserMenu, Button } from "@fsg/gui-bits"
import { ReactComponent as Logo } from "../assets/logo.svg"
import { ReactComponent as Menu } from "../assets/menu.svg"
import "./App.scss"
import useAppStore from "../store/store"
import { apiClient, request } from "../api"
import { JobInterface } from "../types/types"
import { endpoints } from "../constants/endpoints"
import { AuthContext } from "@fsg/apps-api-client-react"
import LoadingPage from "../pages/Auth/Loading/LoadingPage"
import Login from "../pages/Auth/LoginPage"
import { getUserSettings } from "helpers/userSettingsApi";

const AppAuthWrapper = () => {
  const { loading: authIsLoading, isAuthenticated } = useContext(AuthContext)

  if (authIsLoading) return <LoadingPage />

  if (!isAuthenticated) return <Login />

  return <App />
}

function App() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const {
    jobs,
    status: jobsStatus,
    setStatus: setJobsStatus,
    setJobs,
    setError: setJobsError,
  } = useAppStore((state) => state.jobs)
  const {
    setData: setUserSettings,
    status: userSettingsStatus,
    setStatus: setUserSettingsStatus,
    setError: setUserSettingsError,
  } = useAppStore((state) => state.userSettings)

  useEffect(() => {
    setMobileNavOpen(false)
    if (location.pathname === "/") {
      navigate("/dashboard")
    }
  }, [location, navigate])

  useEffect(() => {
    if (!jobs?.length || jobsStatus === "idle") {
      ;(async () => {
        try {
          const response = await request({
            path: `${endpoints.job_risk}`,
          })

          response.jobs = response.jobs.map((job: JobInterface) => ({
            ...job,
            risk:
              job["risk-rating"] >= 50
                ? "high"
                : job["risk-rating"] >= 30
                ? "moderate"
                : "low",
          }))

          setJobs(response.jobs || [])
          setJobsStatus("fulfilled")
          setJobsError(null)
        } catch (e) {
          setJobs([])
          setJobsError(e as Error)
          setJobsStatus("rejected")
        }
      })()
    }
  }, [jobs?.length, jobsStatus, setJobs, setJobsError, setJobsStatus])

  useEffect(() => {
    if (userSettingsStatus !== "idle") return
    setUserSettingsStatus("pending")

    const fetchSettings = async () => {
      try {
        const data = await getUserSettings(user!.email)
        setUserSettings(data)
        setUserSettingsStatus("fulfilled")
        setUserSettingsError(null)
      } catch (e) {
        setUserSettings(null)
        setUserSettingsError(e as Error)
        setUserSettingsStatus("rejected")
      }
    }

    fetchSettings()
  }, [userSettingsStatus, setUserSettings, setUserSettingsError, setUserSettingsStatus, user])

  const UserMenuComponent = () => {
    return (
      <UserMenu
        className="user"
        name={user?.name}
        actions={[
          {
            label: `Logout`,
            onClick: () => {
              window.location.href = apiClient.logoutUri(window.location.origin)
            },
          },
        ]}
      />
    )
  }

  const UserMemo = React.memo(UserMenuComponent)

  return (
    <div className="app-wrapper">
      <div className={`navigation ${mobileNavOpen ? `mobile-open` : ``}`}>
        <NavLink className="logo" to="/">
          <Logo />
        </NavLink>
        <NavItem className="nav-item" to="dashboard" end>
          Risk Monitor
        </NavItem>
        <NavItem className="nav-item" to="manpower" end>
          Division Manpower
        </NavItem>
        <div className="spacer" />
        <UserMemo />
      </div>
      <main>
        <Outlet />

        <footer className="mobile-navigation">
          <NavLink className="logo" to="/">
            <Logo />
          </NavLink>
          <Button
            withIcon
            buttonType="secondary"
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          >
            <Menu />
          </Button>
        </footer>
      </main>
    </div>
  )
}

export default AppAuthWrapper
