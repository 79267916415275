import {
  FinancialText,
  HeaderText,
  HeroKPICard,
  KPICardButton,
  SubHeaderText,
} from "@fsg/gui-bits"
import { Fragment } from "react"
import { JobInterface } from "../../../types/types"
import {
  findAverage,
  findMaxValueJob,
  findMedianValueJob,
  findMinValueJob,
  getValuesArrayByKey,
} from "../helpers"
import { ReactComponent as ClipboardComplete } from "../../../assets/clipboard-complete.svg"
import { ReactComponent as CheckCircle } from "../../../assets/check-circle-2.svg"
import { ReactComponent as MoneyDown } from "../../../assets/money-down.svg"
import { ReactComponent as RiskPulseMonitor } from "../../../assets/risk-pulse-monitor.svg"

interface Props {
  jobs: JobInterface[]
  handleSelectJob: (jobNumber: string) => void
}

const RiskOverview = ({ jobs, handleSelectJob }: Props) => {
  interface RowInterface {
    label: string
    key: keyof JobInterface
    percent?: boolean
    renderPrimary: (value: any) => string
    warningThreshold?: number
  }

  const rows: Array<RowInterface> = [
    {
      label: "% of 920 Depleted",
      key: "rrpct",
      percent: true,
      renderPrimary: (value: number) => `${value.toFixed(2)} %`,
    },
    {
      label: "Baseline Adjustments #",
      key: "pm-adjs-ct",
      renderPrimary: (value: number) => `${Math.floor(value)}`,
    },
    {
      label: "Temp Labor Ratio (%)",
      key: "thrpct",
      percent: true,
      warningThreshold: 50,
      renderPrimary: (value: any) => `${value.toFixed(2)} %`,
    },
    {
      label: "Consecutive Underbillings",
      key: "cons-undrbil",
      renderPrimary: (value: number) => `${Math.floor(value)}`,
    },
  ]

  return (
    <section id="risk-overview">
      <HeaderText>Risk Overview</HeaderText>
      <section id="hero-cards" className="hero-cards">
        <HeroKPICard
          icon={RiskPulseMonitor}
          label="Jobs at Risk"
          primary={`${jobs.length}`}
        />
        <HeroKPICard
          icon={MoneyDown}
          label="Current $ Underbilled"
          primary={
            <FinancialText>{`${jobs.reduce(
              (acc, job) => acc + (job.undbill || 0),
              0
            )}`}</FinancialText>
          }
        />
      </section>
      {jobs.length
        ? rows.map(({ label, key, renderPrimary }) => {
            const flatValuesArray = getValuesArrayByKey(key, jobs) as number[]
            const average = findAverage(flatValuesArray)
            const medianValueJob = findMedianValueJob(key, jobs)
            const maxValueJob = findMaxValueJob(key, jobs)
            const minValueJob = findMinValueJob(key, jobs)

            return (
              <Fragment key={key}>
                <SubHeaderText>{label}</SubHeaderText>
                <div className="grid">
                  <HeroKPICard
                    className="hero-card kpi-card"
                    icon={ClipboardComplete}
                    label={`Avg`}
                    primary={renderPrimary(average)}
                  />
                  <KPICardButton
                    className="kpi-card"
                    label={`Max`}
                    primary={renderPrimary(maxValueJob[key] as number)}
                    secondary={`${maxValueJob.jobno} - ${maxValueJob.jobnm}`}
                    icon={CheckCircle}
                    onClick={() => handleSelectJob(maxValueJob.jobno)}
                  />
                  <KPICardButton
                    className="kpi-card"
                    label={`Min`}
                    primary={renderPrimary(minValueJob[key] as number)}
                    secondary={`${minValueJob.jobno} - ${minValueJob.jobnm}`}
                    icon={CheckCircle}
                    onClick={() => handleSelectJob(minValueJob.jobno)}
                  />
                  <KPICardButton
                    className="kpi-card"
                    label={`Median`}
                    primary={renderPrimary(medianValueJob[key] as number)}
                    secondary={`${medianValueJob?.jobno} - ${medianValueJob?.jobnm}`}
                    icon={CheckCircle}
                    onClick={() => handleSelectJob(medianValueJob.jobno)}
                  />
                </div>
              </Fragment>
            )
          })
        : null}
    </section>
  )
}
export default RiskOverview
