import { request } from "api"
import { endpoints } from "constants/endpoints"
import useAppStore from "store/store"
import { CustomRiskProfile, UserSettingsInterface } from "types/types"

const endpoint = endpoints.userSettings
const { getState } = useAppStore

export const prepareProfilesForDB = (profiles: CustomRiskProfile[]) => {
  // prepared profiles will exclude properties used only for state management such as `_id` and `isStored`
  return profiles.map((profile, i) => ({
    name: profile.name || `Custom Profile ${i + 1}`,
    settings: profile.settings,
  }))
}

export const getUserSettings = async (username: string) => {
  const data = await request({
    path: `${endpoint}/by_user/${username}`,
  })
  return data
} // used on the first time a user is creating settings, which means no profile will be in the DB and therefore _id will be undefined in state and that the username will be required

export const createUserSettings = async ({
  username,
  settings,
}: {
  username: string
  settings: Partial<UserSettingsInterface>
}) => {
  try {
    const data = await request({
      method: "POST",
      path: `${endpoint}`,
      requestOptions: {
        body: JSON.stringify({ ...settings, user_name: username }),
      },
    })
    return data
  } catch (error) {
    console.log(error)
    throw new Error(`${error}`)
  }
} // settings argument is a partial, meaning whichever settings are being passed into the function call are the only ones that are being updated. E.g., if the component is responsible for handling risk profiles, it need only pass the risk_profiles property.

export const updateUserSettings = async (
  args: Partial<UserSettingsInterface>
) => {
  const { userSettings } = getState()

  try {
    if (!userSettings.data._id)
      throw new Error(
        `Settings not found for this user, use create function instead`
      )

    const settingsInState = userSettings.data
    const data = await request({
      method: "PUT",
      path: `${endpoint}/${userSettings.data._id}`,
      requestOptions: {
        body: JSON.stringify({ ...settingsInState, ...args }),
      },
    })
    return data
  } catch (error) {
    console.log(error)
    throw new Error(`${error}`)
  }
}