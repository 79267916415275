import { useEffect, useState } from "react"
import { request } from "../../api"
import { endpoints } from "../../constants/endpoints"
import {
  CCDetailInterface,
  SummaryBudgetInterface,
  Summary1Interface,
  Summary2Interface,
  Status,
  // Status,
} from "../../types/types"

export type DataType = {
  summary1: Summary1Interface[]
  summary2: Summary2Interface[]
  budget: SummaryBudgetInterface[]
  ccdetail: CCDetailInterface[]
}

const useGetJobSummary = (jobNumber: string) => {
  const [data, setData] = useState<DataType>()
  const [status, setStatus] = useState<Status>("idle") // idle, pending, fulfilled, or rejected
  const [error, setError] = useState<Error | null>()

  useEffect(() => {
    setStatus("pending")

    const fetchData = async () => {
      try {
        const response: any = await request({
          path: `${endpoints.job_summary}?jobno=${jobNumber}`,
        })
        setData(
          {
            summary1: response.summary1.data || [],
            summary2: response.summary2.data || [],
            budget: response.budget.data || [],
            ccdetail: response.ccdetail.data || [],
          } || undefined
        )
        setStatus("fulfilled")
        setError(null)
      } catch (e) {
        setError(e as Error)
        setStatus("rejected")
      }
    }

    fetchData()
  }, [jobNumber])

  return { data, status, error }
}

export default useGetJobSummary
