import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Interaction,
} from "chart.js"
import CrosshairPlugin, { Interpolate } from "chartjs-plugin-crosshair"
import { Line } from "react-chartjs-2"
import {ErrorBoundary} from "react-error-boundary";
import FallBack from "../../ErrorFallBack";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  CrosshairPlugin
)
Interaction.modes.interpolate = Interpolate

export const JobAnalysis920Chart = (props) => {
  let { data } = props

  data.sort((a, b) => {
    return new Date(a.fiscal_date) - new Date(b.fiscal_date)
  })
  data = data.filter((x) => x.cost_code === "920")

  let dataSet = [];
  let balance = 0;
  data.forEach((x) => {
    const f_date = new Date(x.fiscal_date);
    const key = `${f_date.getMonth() + 1}/${f_date.getFullYear()}`;
    let obj = dataSet.find((y) => y.key === key);

    balance += x.other;

    if (!obj) {
      dataSet.push({
        key,
        fiscal_date: f_date,
        value: balance
      })
    } else {
      obj.value = balance
    }
  })

  if (data.length === 0) return <>No 920 Data</>

  for (let i = 0; i < dataSet.length - 1; i++) {
    const data = dataSet[i];
    const nextData = dataSet[i + 1];
    let month = parseInt(data.key.split("/")[0], 10);
    let nextMonth = parseInt(nextData.key.split("/")[0], 10);
    let nextExpectedMonth = month === 12 ? 1 : month + 1;

    if (nextMonth !== nextExpectedMonth) {
      let insertion = JSON.parse(JSON.stringify(data));
      let year = new Date(insertion.fiscal_date).getFullYear();
      let key = `${nextExpectedMonth}/${year}`;
      if (nextExpectedMonth === 1) {
        year += 1;
      }

      //set fiscal date to next month
      insertion.fiscal_date = new Date(year, nextExpectedMonth - 1, 15);
      insertion.key = key;

      dataSet.splice(i + 1, 0, insertion);
    }
  }

  const chartData = {
    labels: dataSet.map((x) =>
      x.key
    ),
    datasets: [
      {
        type: "line",
        label: "920 Balance",
        data: dataSet.map(x => x.value),
        backgroundColor: "rgba(34, 199, 153, 0.5)",
        borderColor: "rgba(34, 199, 153, 1)",
        fill: false,
      },
    ],
  }

  return (
      <ErrorBoundary FallbackComponent={FallBack}>
        <div style={{ position: "relative", display: "block", width: "100%" }}>
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              aspectRatio: 3,
              plugins: {
                title: { text: "920 Balance", display: true },
                crosshair: { enabled: true },
                tooltip: {
                  mode: "interpolate",
                  intersect: false,
                },
              },
            }}
            plugins={CrosshairPlugin}
          />
        </div>
      </ErrorBoundary>
  )
}
