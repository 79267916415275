// mapping for common times in minutes used for lscache expiration parameters
export const CACHE_TIME = {
  DAYS: {
    // [days]: minutes
    1: 1440,
    2: 2880,
    3: 4320,
    4: 5760,
    5: 7200,
    10: 14400,
    30: 43200,
  },
}
