export const endpoints = {
  search: "/v1/jobs/search",
  info: "/v1/jobs/info",
  divisions: "/v1/jobs/divisions",
  job_summary: "/v1/jobs/budget-summary",
  manpower: "/v1/jobs/manpower",
  pos: "/v1/jobs/pos",
  tax_audit: "/v1/jobs/tax-audit",
  account_history: "/v1/jobs/account-history",
  cashflow: "/v1/jobs/cashflow",
  po_recap: "/v1/jobs/porecap",
  cost_details: "/v1/jobs/cost-details",
  production_analysis: "/v1/jobs/production-analysis",
  team_manpower_forecast: "/v1/jobs/team-manpower-forecast",
  budgets: "/v1/jobs/budgets",
  sov: "/v1/jobs/sov",
  ar_invoice: "/v1/jobs/ar-invoice",
  labor_baselines: "/v1/jobs/labor-baselines",
  material_baselines: "/v1/jobs/material-baselines",
  job_risk: "/v1/jobs/job-risk",
  customer_kpis: "/v1/sla/customer-kpis",
  userSettings: "/v1/user-settings",
  risk_scorecard: `/v1/rubrics/${process.env.REACT_APP_RISK_RUBRIC_ID}/calculate`,
  profit_performance: "/v1/jobs/profit-performance",

}
