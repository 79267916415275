import App from "../App"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import JobOverviewPage from "../pages/JobOverview"
import DashboardPage from "../pages/Dashboard"
import RiskMonitorPage from "../pages/RiskMonitor"
import LeaderboardPage from "../pages/Leaderboard"
import Manpower from "../pages/Manpower"

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/:jobNumber" element={<JobOverviewPage />} />
          <Route path="/dashboard" element={<DashboardPage />}>
            <Route index element={<RiskMonitorPage />} />
            <Route path="leaderboard" element={<LeaderboardPage />} />
          </Route>
          <Route path="manpower" element={<Manpower />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
