const compactFormatter = Intl.NumberFormat("en", {
  notation: "compact",
  style: "currency",
  currency: "USD",
})
const standardFormatter = Intl.NumberFormat("en", {
  notation: "standard",
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

const formatCurrency = {
  compact: (number: number) => {
    return compactFormatter.format(number)
  },
  standard: (number: number) => {
    return standardFormatter.format(number)
  },
}

export default formatCurrency
